import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ProjectBox from "components/Elements/ProjectBox";
import FullButton from "components/Buttons/FullButton";
import { getProjects } from "store/actions/cmsActions";
import { FaFolderOpen } from "react-icons/fa";

const ProjectsPage = () => {
  const navigate = useNavigate();
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';

  const handleViewAll = () => {
    navigate("/projects");
  };

  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.cmsReducer);

  useEffect(() => {
    dispatch(getProjects(organizationId));
  }, [dispatch, organizationId]);

  const projectList = projects?.length > 0 ? (
    projects.map((project, i) => (
      <div key={i} className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
        <ProjectBox
          id={project?.id}
          title={project?.title}
          description={project?.description}
          tag="Community"
          author="GPTF"
          img={project?.imageUrl}
        />
      </div>
    ))
  ) : (
    <NoContentMessage>
      <FaFolderOpen size={50} />
      <h2>No Projects Available</h2>
      <p>There are currently no projects available. Please check back later.</p>
    </NoContentMessage>
  );

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Projects</h1>
            <p className="font13">
              Explore our range of conservation and community development projects across Namibia.
              These initiatives showcase our commitment to wildlife protection and sustainable resource management.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {projectList}
          </div>
          {projects?.length > 0 && (
            <div className="row flexCenter">
              <div style={{ margin: "50px 0", width: "200px" }}>
                <FullButton title="View All Projects" action={handleViewAll} />
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 32px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;


const NoContentMessage = styled.div`
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: #667140;
    margin: 1rem 0;
  }

  p {
    color: #827c63;
  }

  svg {
    color: #a59650;
  }
`;

export default ProjectsPage;

import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import HeaderBg from "../../assets/img/support-us.avif";

const colors = {
  primary: "#8c9c6c",
  secondary: "#a59650",
  tertiary: "#667140",
  neutral: "#827c63",
  light: "#f5f5f5",
  success: "#4CAF50",
  error: "#f44336",
  background: "#f8f8f2",
  accent: "#a59650",
};

const PledgePage = () => {
  const [selectedPledge, setSelectedPledge] = useState(null);
  const [customPledge, setCustomPledge] = useState("");
  const [selectedInitiatives, setSelectedInitiatives] = useState([]);
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    pledgeType: "monetary",
    nonMonetaryDescription: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handlePledgeSelect = (amount) => {
    setSelectedPledge(amount);
    setCustomPledge("");
    setFormDetails((prev) => ({ ...prev, pledgeType: "monetary" }));
  };

  const handleCustomPledgeChange = (e) => {
    setCustomPledge(e.target.value);
    setSelectedPledge(null);
    setFormDetails((prev) => ({ ...prev, pledgeType: "monetary" }));
  };

  const handleInitiativeSelect = (initiative) => {
    setSelectedInitiatives((prev) =>
      prev.includes(initiative)
        ? prev.filter((i) => i !== initiative)
        : [...prev, initiative]
    );
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedInitiatives.length === 0) {
      alert("Please select at least one initiative.");
      return;
    }
    setIsSubmitted(true);
    setSelectedPledge(null);
    setCustomPledge("");
    setSelectedInitiatives([]);
    setFormDetails({
      name: "",
      email: "",
      pledgeType: "monetary",
      nonMonetaryDescription: "",
    });

    setTimeout(() => {
      setIsSubmitted(false);
    }, 3000);
  };

  return (
    <Wrapper>
     <Header>
        <HeaderTitle>Make a Pledge</HeaderTitle>
        <HeaderSubtitle>Support Our Causes</HeaderSubtitle>
      </Header>
      <Container>
        <Section>
          <SectionTitle>Select a Pledge</SectionTitle>
          <PledgeOptions>
            {[50, 100, 200].map((amount) => (
              <PledgeButton
                key={amount}
                selected={selectedPledge === amount}
                onClick={() => handlePledgeSelect(amount)}
              >
                ${amount}
              </PledgeButton>
            ))}
            <CustomPledgeInput
              type="number"
              placeholder="Enter custom amount"
              value={customPledge}
              onChange={handleCustomPledgeChange}
            />
          </PledgeOptions>
          <NonMonetarySection>
            <NonMonetaryLabel>
              <Checkbox
                type="radio"
                name="pledgeType"
                value="non-monetary"
                checked={formDetails.pledgeType === "non-monetary"}
                onChange={handleFormChange}
              />
              Non-Monetary Pledge
            </NonMonetaryLabel>
            {formDetails.pledgeType === "non-monetary" && (
              <NonMonetaryInput
                type="text"
                name="nonMonetaryDescription"
                placeholder="Describe your non-monetary pledge"
                value={formDetails.nonMonetaryDescription}
                onChange={handleFormChange}
              />
            )}
          </NonMonetarySection>
        </Section>

        <Section>
          <SectionTitle>Select Initiative(s)</SectionTitle>
          <InitiativeOptions>
            {[
              "Wildlife Conservation",
              "Community Development",
              "Education",
              "Sustainable Practices",
            ].map((initiative) => (
              <InitiativeButton
                key={initiative}
                selected={selectedInitiatives.includes(initiative)}
                onClick={() => handleInitiativeSelect(initiative)}
              >
                {initiative}
              </InitiativeButton>
            ))}
          </InitiativeOptions>
        </Section>

        <Section>
          <SectionTitle>Your Details</SectionTitle>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Name</FormLabel>
              <FormInput
                type="text"
                name="name"
                value={formDetails.name}
                onChange={handleFormChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Email</FormLabel>
              <FormInput
                type="email"
                name="email"
                value={formDetails.email}
                onChange={handleFormChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <CheckboxLabel>
                <Checkbox type="checkbox" required />
                I agree, and I am making this pledge in good faith.
              </CheckboxLabel>
            </FormGroup>
            <SubmitButton type="submit">Submit Pledge</SubmitButton>
          </Form>
        </Section>

        {isSubmitted && (
          <SuccessMessage>
            Your pledge was successfully submitted! Thank you for your support.
          </SuccessMessage>
        )}
      </Container>
    </Wrapper>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Wrapper = styled.div`
  background: ${colors.background};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 5rem; 
`;


const Header = styled.header`
  width: 100%; 
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  color: white;
  padding: 4rem 0; 
  text-align: center;
  margin-top: 0; 
  position: relative; 
`;

const HeaderTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeaderSubtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
`;

const Container = styled.div`
  max-width: 900px;
  margin: 2rem auto;
  background: white;
  padding: 2.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 2px solid ${colors.accent};
  position: relative;
`;

const Section = styled.div`
  margin-bottom: 2.5rem;
`;

const SectionTitle = styled.h3`
  font-size: 2rem;
  color: ${colors.tertiary};
  margin-bottom: 1rem;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 60px;
    height: 4px;
    background-color: ${colors.secondary};
    border-radius: 2px;
  }
`;

const PledgeOptions = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const PledgeButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: ${({ selected }) =>
    selected ? colors.secondary : colors.primary};
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.secondary};
    transform: translateY(-3px);
  }
`;

const CustomPledgeInput = styled.input`
  padding: 0.75rem;
  border: 2px solid ${colors.neutral};
  border-radius: 6px;
  flex: 1;
  font-size: 1.2rem;
`;

const NonMonetarySection = styled.div`
  margin-top: 1.5rem;
`;

const NonMonetaryLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const NonMonetaryInput = styled.input`
  padding: 0.75rem;
  border: 2px solid ${colors.neutral};
  border-radius: 6px;
  width: 100%;
  font-size: 1.2rem;
`;

const InitiativeOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const InitiativeButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: ${({ selected }) =>
    selected ? colors.secondary : colors.primary};
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.secondary};
    transform: translateY(-3px);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: ${colors.tertiary};
`;

const FormInput = styled.input`
  padding: 0.75rem;
  border: 2px solid ${colors.neutral};
  border-radius: 6px;
  width: 100%;
  font-size: 1.2rem;
`;

const CheckboxLabel = styled.label`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const SubmitButton = styled.button`
  padding: 0.75rem;
  background-color: ${colors.accent};
  color: white;
  font-size: 1.4rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.secondary};
    transform: translateY(-3px);
  }
`;

const SuccessMessage = styled.div`
  margin-top: 2rem;
  padding: 1rem 2rem;
  background-color: ${colors.success};
  color: white;
  font-size: 1.4rem;
  text-align: center;
  border-radius: 6px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export default PledgePage;

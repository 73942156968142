import React from 'react';
import styled from 'styled-components';
import TopNavbar from "../../components/Nav/TopNavbar";

const FundingGuidelines = () => {
  return (
    <>
      <TopNavbar />
      <PageWrapper>
        <Header>
          <HeaderTitle>Funding Guidelines</HeaderTitle>
          <HeaderSubtitle>Essential information for GPTF funding applicants</HeaderSubtitle>
        </Header>
        
        <Section>
          <Title>Funding Criteria</Title>
          <Content>
            The GPTF only funds projects that comply and meet its objectives as stipulated in Act 7 of 1997. Additionally, the GPTF has its own criteria governing what it wishes to consider funding. Applicants to the GPTF need to be made up of non-profit organizations, from small community groups such as conservancies to larger ones such as the Ministry of Environment and Tourism. The allocation of funds is based on priorities determined by the Board on an annual basis, and is centered on the principle of:
            <List>
              <ListItem>Returning funds to areas of origin of the wildlife products;</ListItem>
              <ListItem>Supporting measures aimed at improving the relationship between people and wildlife;</ListItem>
              <ListItem>Supporting improvements in the monitoring, management, protection, sustainable use, and development of wildlife resources in rural areas; and</ListItem>
              <ListItem>Striking a balance between the allocation of funds for rural development and for wildlife resource monitoring, conservation, and protection.</ListItem>
            </List>
            The GPTF supports wildlife conservation and community conservation and developmental programs aimed at harmonizing the coexistence of people and wildlife. When considering applications, the GPTF looks for evidence of improvement of human-wildlife relationships, inclusivity and need, local community involvement, and sustainability. Please read the GPTF guidelines, criteria, and procedures for applying for funding under the "Forms & Guidance" section to confirm that your project fits/meets the GPTF funding guidelines.
          </Content>
        </Section>

        <Section>
          <Title>Submission Deadlines</Title>
          <Content>
            The GPTF has four funding rounds annually, and applications received two weeks before the closing dates will be considered on the next decision date. However, there is always the possibility that a project could be deferred to a later decision date for further information to be obtained. Please ensure that all proposals or applications reach the Fund Management Office by the following deadlines:
            <List>
              <ListItem>First round: 15th January</ListItem>
              <ListItem>Second round: 15th April</ListItem>
              <ListItem>Third round: 15th July</ListItem>
              <ListItem>Fourth round: 15th October</ListItem>
            </List>
          </Content>
        </Section>

        <Section>
          <Title>Forms & Guidance</Title>
          <Content>
            This page provides the applicant with resources to apply for funding:
            <List>
              <ListItem>GPTF guidelines, criteria, and procedures for applying for funding</ListItem>
              <ListItem>The funding process</ListItem>
              <ListItem>Sample Grant Agreement</ListItem>
              <ListItem>Progress report form</ListItem>
              <ListItem>Final report form</ListItem>
            </List>
          </Content>
        </Section>
{/* 
        <Section>
          <Title>Useful Links</Title>
          <Content>
            <List>
              <ListItem><StyledLink href="https://www.meft.gov.na" target="_blank" rel="noopener noreferrer">Ministry of Environment, Forestry & Tourism</StyledLink></ListItem>
              <ListItem><StyledLink href="https://www.mawf.gov.na" target="_blank" rel="noopener noreferrer">Ministry of Agriculture, Water & Land Reform</StyledLink></ListItem>
              <ListItem><StyledLink href="https://www.mfpe.gov.na" target="_blank" rel="noopener noreferrer">Ministry of Finance & Public Enterprises</StyledLink></ListItem>
              <ListItem><StyledLink href="https://www.eif.com" target="_blank" rel="noopener noreferrer">Environmental Investment Fund of Namibia</StyledLink></ListItem>
              <ListItem><StyledLink href="#" target="_blank" rel="noopener noreferrer">Namibia Tourism Board</StyledLink></ListItem>
              <ListItem><StyledLink href="https://www.nwr.com.na" target="_blank" rel="noopener noreferrer">Namibia Wildlife Resorts</StyledLink></ListItem>
              <ListItem><StyledLink href="https://www.cites.org" target="_blank" rel="noopener noreferrer">CITES</StyledLink></ListItem>
            </List>
          </Content>
        </Section> */}

        {/* <Section>
          <Title>Disclaimer & Privacy Policy</Title>  
          <Content>
            The Game Products Trust Fund has carefully tried to make sure that the information on this website is accurate at the time of its inclusion. However, it cannot be held liable or accept responsibility for any loss or damage that may arise from any errors or omissions appearing on this website. Any user who relies or uses such erroneous information should know that they will be doing so entirely at their own risk. Links from this site to other sites do not imply any endorsement either of the website supplier or its content. The Game Products Trust Fund carries no responsibility for the accuracy of any information on the sites concerned.
          </Content>
        </Section> */}
      </PageWrapper>
    </>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const PageWrapper = styled.div`
  padding: 2rem;
  background-color: ${colors.light};
  max-width: 1200px;
  margin: 70px auto 0;
  font-family: Arial, sans-serif;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 5rem;
  padding-bottom: 2rem;
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  color: ${colors.tertiary};
  margin-bottom: 0.5rem;
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};
`;

const Section = styled.section`
  margin-bottom: 2rem;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: ${colors.primary};
  margin-bottom: 1rem;
  border-bottom: 2px solid ${colors.primary};
  padding-bottom: 0.5rem;
`;

const Content = styled.div`
  font-size: 1rem;
  color: ${colors.neutral};
  line-height: 1.6;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;

  &:before {
    content: '•';
    position: absolute;
    left: 0;
    color: ${colors.primary};
  }
`;

const StyledLink = styled.a`
  color: ${colors.primary};
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${colors.secondary};
    text-decoration: underline;
  }
`;

export default FundingGuidelines;
import Man1 from "../assets/img/team/man-team-1.avif";
import Man2 from "../assets/img/team/man-team-2.avif";
import Man3 from "../assets/img/team/man-team-3.avif";
import Woman1 from "../assets/img/team/woman-team-1.avif";
import Woman2 from "../assets/img/team/woman-team-2.avif";
import Woman3 from "../assets/img/team/woman-team-3.avif";

export const executiveOrgChartData = {
  name: "Mr. Frans Kamenye",
  title: "Fund Manager/CEO",
  image: Man1,
  children: [
    {
      name: "Mrs. Martha Ndove",
      title: "Manager: Finance & Administration",
      image: Woman1,
    },  
  ],
};

import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PartnersCarousel = () => {
  const clients = [
    { id: 1, logo: 'https://mariongrandvincent.github.io/HTML-Personal-website/img-codePen/slider-logo1.png' },
    { id: 2, logo: 'https://mariongrandvincent.github.io/HTML-Personal-website/img-codePen/slider-logo2.png' },
    { id: 3, logo: 'https://mariongrandvincent.github.io/HTML-Personal-website/img-codePen/slider-logo3.png' },
    { id: 4, logo: 'https://mariongrandvincent.github.io/HTML-Personal-website/img-codePen/slider-logo1.png' },
    { id: 5, logo: 'https://mariongrandvincent.github.io/HTML-Personal-website/img-codePen/slider-logo2.png' },
    { id: 6, logo: 'https://mariongrandvincent.github.io/HTML-Personal-website/img-codePen/slider-logo3.png' },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <ClientSectionWrapper>
      <Container>
        <SectionTitle>
          <h2>Our Partners and Sponsors</h2>
        </SectionTitle>
        <SliderWrapper>
          <Slider {...settings}>
            {clients.map(client => (
              <Slide key={client.id}>
                <img src={client.logo} alt={`Client ${client.id}`} />
              </Slide>
            ))}
          </Slider>
        </SliderWrapper>
      </Container>
    </ClientSectionWrapper>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5',
  border: '#e0e0e0',
};

const ClientSectionWrapper = styled.section`
  padding: 4em 0;
  background-color: ${colors.light};
  border-top: 2px solid ${colors.border};
  border-bottom: 2px solid ${colors.border};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const SectionTitle = styled.div`
  margin-bottom: 2em;
  text-align: center;
  
  h2 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 6px;
    color: ${colors.tertiary};
    position: relative;
    
    &::after {
      content: "";
      height: 3px;
      background: ${colors.secondary};
      width: 80px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
      margin: auto;
    }
  }
`;

const SliderWrapper = styled.div`
  .slick-prev, .slick-next {
    display: none !important;
  }
  
  .slick-dots {
    text-align: center;
    padding-top: 20px;
    
    li {
      margin: 0 5px;
      
      button {
        &::before {
          font-size: 10px;
          color: ${colors.neutral};
          opacity: 1;
        }
      }
      
      &.slick-active button::before {
        color: ${colors.secondary};
      }
    }
  }
`;

const Slide = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

export default PartnersCarousel;

// Aside.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Aside = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AsideWrapper isOpen={isOpen}>
      <ToggleButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? '>' : '<'}
      </ToggleButton>
      <AsideContent isOpen={isOpen}>
        <AsideHeader>Quick Links</AsideHeader>
        <LinkList>
          <LinkItem><StyledLink to="/">Home</StyledLink></LinkItem>
          {/* <LinkItem><StyledLink to="/login">Login</StyledLink></LinkItem> */}
          <LinkItem><StyledLink to="/about-mission">Mission</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/about-partners">Partners</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/procurement-bids">Procurement Bids</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/procurement-awards">Procurement Awards</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/organization-management">Organization Management</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/blogs">Blogs</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/photo-gallery">Photo Gallery</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/video-gallery">Video Gallery</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/downloads">Downloads</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/projects">Projects</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/about-us">About Us</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/contact">Contact Us</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/procurement-conditions">Procurement Conditions</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/procurement-legislation">Procurement Legislation</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/procurement-plan">Procurement Plan</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/getting-funded">Getting Funded</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/funding-guidelines">Funding Guidelines</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/vacancies">Vacancies</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/bursaries">Bursaries</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/completed-projects">Completed Projects</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/funded-projects">Funded Projects</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/about-history">History</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/about-values">Values</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/board-of-directors">Board of Directors</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/executive-committee">Executive Committee</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/staff">Staff</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/faqs">FAQs</StyledLink></LinkItem>
          <LinkItem><StyledLink to="/support-us">Support Us</StyledLink></LinkItem>
        </LinkList>
      </AsideContent>
    </AsideWrapper>
  );
};

const AsideWrapper = styled.aside`
  position: fixed;
  right: ${props => props.isOpen ? '0' : '-250px'};
  top: 50%;
  transform: translateY(-50%);
  width: 250px;
  height: 40vh;
  background: #60743c;
  border-left: 2px solid #60743c;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: right 0.3s ease-in-out;
  display: flex;
  border-radius: 10px 0 0 10px;
`;

const ToggleButton = styled.button`
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  background: #60743c;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 5px 0 0 5px;
  transition: background 0.3s;

  &:hover {
    background: #a59650;
  }
`;

const AsideContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
  display: ${props => props.isOpen ? 'block' : 'none'};
  color: #ecf0f1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #60743c;
  }

  &::-webkit-scrollbar-thumb {
    background: #a59650;
    border-radius: 4px;
  }
`;

const AsideHeader = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #a59650;
  padding-bottom: 0.5rem;
  color: #a59650;
`;

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const LinkItem = styled.li`
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 5px;
  transition: background 0.3s;

  &:hover {
    background: #a59650;
    color: #60743c;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #ecf0f1;
  font-size: 1rem;
  transition: color 0.3s;
  display: block;

  &:hover {
    color: #60743c;
  }
`;

export default Aside;
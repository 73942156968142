import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const FeedbackForm = ({ onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    feedbackType: '',
    rating: '',
    comments: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFeedbackSubmit = (e) => {
    e.preventDefault();

    const apiEndpoint = 'https://qualis-email-server.vercel.app/api/custom-mails/gptf/gptf-feedback';
    // const apiEndpoint = 'http://localhost:5001/api/custom-mails/gptf/gptf-feedback';

    // Fetch options
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData) // Convert form data to JSON
    };

    // Make the request
    fetch(apiEndpoint, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        alert('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          feedbackType: '',
          rating: '',
          comments: '',
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error occurred while sending the message. Please try again later.');
      });
  };

  return (
    <FormOverlay>
      <FormContainer>
        <h2>Provide Feedback</h2>
        <form onSubmit={handleFeedbackSubmit}>
          <Input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
          <Input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
          <Select name="feedbackType" value={formData.feedbackType} onChange={handleChange} required>
            <option value="">Select Feedback Type</option>
            <option value="complaint">Complaint</option>
            <option value="suggestion">Suggestion</option>
            <option value="compliment">Compliment</option>
          </Select>
          <RatingContainer>
            <label>Rate your experience:</label>
            {[1, 2, 3, 4, 5].map((value) => (
              <RatingInput
                key={value}
                type="radio"
                name="rating"
                value={value}
                checked={formData.rating === value.toString()}
                onChange={handleChange}
                required
              />
            ))}
          </RatingContainer>
          <TextArea name="comments" placeholder="Your Comments" value={formData.comments} onChange={handleChange} required />
          <Button type="submit">Submit Feedback</Button>
        </form>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </FormContainer>
    </FormOverlay>
  );
};



// Styled Components with 3D effect and consistent design

const FormOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FormContainer = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border: 2px solid #9bbb59;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  font-family: 'Courier New', Courier, monospace;
  transform: perspective(800px) rotateX(5deg) rotateY(-5deg);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;

  &:hover {
    transform: perspective(800px) rotateX(0deg) rotateY(0deg);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }

  h2 {
    color: #9bbb59;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #9bbb59;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #9bbb59;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #9bbb59;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  background-color: #9bbb59;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #006666;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #9bbb59;
`;

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const RatingInput = styled.input`
  margin-left: 10px;
`;

export default FeedbackForm;

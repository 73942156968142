import React from 'react';
import styled from 'styled-components';
import LogoImg from "../../assets/img/logo.png";

const ProcurementLegislationPage = () => {

  const publicProcurementAct15 = "/data/PublicProcurementLeg/Gov N320-Public Procurement Act 15 of 2015.pdf";
  const procurementLegislations = "/data/PublicProcurementLeg/Public Procurement Regulations.pdf";
  const procurementGuidlines = "/data/PublicProcurementLeg/3 Public Procurement Guidelines 2017.pdf";

  const actDocuments = [
    {
      title: "Public Procurement Act - 15 0f 2015",
      size: "2.5 MB",
      link: publicProcurementAct15
    },
  ];

  const regulationDocuments = [
    {
      title: "Procurement Regulations",
      size: "1.5 MB",
      link: procurementLegislations
    }
  ];

  const guidelineDocuments = [
    { 
      title: "Procurement Guidelines",
      size: "1.2 MB",
      link: procurementGuidlines
    }
  ];

  return (
    <PageWrapper>
      <TitleWrapper>
        <Title>LEGISLATION</Title>
        <TitleLine />
      </TitleWrapper>

      {/* ACT Section */}
      <Section>
        <SectionTitle>ACT</SectionTitle>
        <Divider />
        <Container>
          {actDocuments.map((doc, index) => (
            <DocumentCard key={index}>
              <Logo src={LogoImg} alt="Logo" />
              <Content>
                <DocumentTitle>{doc.title}</DocumentTitle>
                <DocumentSize>{doc.size}</DocumentSize>
                <Line />
                <DownloadButton href={doc.link} download>Download</DownloadButton>
              </Content>
            </DocumentCard>
          ))}
        </Container>
      </Section>

      {/* Regulations Section */}
      <Section>
        <SectionTitle>Regulations</SectionTitle>
        <Divider />
        <Container>
          {regulationDocuments.map((doc, index) => (
            <DocumentCard key={index}>
              <Logo src={LogoImg} alt="Logo" />
              <Content>
                <DocumentTitle>{doc.title}</DocumentTitle>
                <DocumentSize>{doc.size}</DocumentSize>
                <Line />
                <DownloadButton href={doc.link} download>Download</DownloadButton>
              </Content>
            </DocumentCard>
          ))}
        </Container>
      </Section>

      {/* Guidelines Section */}
      <Section>
        <SectionTitle>Guidelines</SectionTitle>
        <Divider />
        <Container>
          {guidelineDocuments.map((doc, index) => (
            <DocumentCard key={index}>
              <Logo src={LogoImg} alt="Logo" />
              <Content>
                <DocumentTitle>{doc.title}</DocumentTitle>
                <DocumentSize>{doc.size}</DocumentSize>
                <Line />
                <DownloadButton href={doc.link} download>Download</DownloadButton>
              </Content>
            </DocumentCard>
          ))}
        </Container>
      </Section>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  position: relative;
  padding: 20px; /* Adjusted padding for mobile */
  max-width: 1200px;
  margin: 0 auto;
  top: 70px;

  @media (max-width: 768px) {
    padding: 10px; /* Less padding for smaller screens */
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: 20px; /* Adjusted for mobile */
  padding-top: 20px;
  color: #9bbb59;
`;

const Title = styled.h1`
  font-size: 2rem; /* Adjusted for mobile */
  font-weight: 600;
  margin: 0;
  padding-top: 5rem;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller title on mobile */
  }
`;

const TitleLine = styled.div`
  position: absolute;
  margin-top: 3rem;
  top: 20%;
  left: 0;
  width: 15%;
  height: 4px;
  background-color: #9bbb59;
  transform: translateY(-50%);
`;

const Section = styled.div`
  margin-bottom: 40px;
  padding: 40px 0; /* Adjusted for mobile */
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem; /* Adjusted for mobile */
  font-weight: 500;
  margin-bottom: 20px;
  color: #9bbb59;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Smaller section title on mobile */
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #9bbb59;
  margin-bottom: 20px;
`;

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr); 

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }
`;

const DocumentCard = styled.div`
  color: #c4ba91;
  display: flex;
  align-items: center;
  background: #c3b98f21;
  padding: 20px;
  border: 0.5px solid #61743c;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: flex-start; /* Align to start */
    padding: 15px; /* Adjusted padding */
  }
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border: 0.5px solid #61743c;
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 40px; /* Smaller logo on mobile */
    height: 40px;
    margin-right: 10px; /* Adjusted margin */
  }
`;

const Content = styled.div`
  flex: 1;
`;

const DocumentTitle = styled.h3`
  font-size: 1.25rem;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller title on mobile */
  }
`;

const DocumentSize = styled.p`
  font-size: 0.875rem;
  color: #666;
  margin: 5px 0;

  @media (max-width: 768px) {
    font-size: 0.75rem; /* Smaller size text on mobile */
  }
`;

const Line = styled.div`
  height: 1px;
  background-color: #eee;
  margin: 10px 0;
`;

const DownloadButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  font-size: 0.875rem;
  color: #fff;
  background-color: #9bbb59;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #005bb5;
  }

  @media (max-width: 768px) {
    padding: 8px 16px; /* Smaller button padding on mobile */
    font-size: 0.75rem; /* Smaller font size on mobile */
  }
`;

export default ProcurementLegislationPage;

import React, { useState } from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contactUs1.webp";
import ContactImg2 from "../../assets/img/5.jpg";
import ContactImg3 from "../../assets/img/contactUs.png";

export default function Contact() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const response = await fetch("http://localhost:5001/api/custom-mails/gptf/gptf-contact-us", {
        const response = await fetch("https://qualis-email-server.vercel.app/api/custom-mails/gptf/gptf-contact-us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form");
      }

      alert("Message sent successfully!");
      setFormData({
        fullName: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending the message. Please try again.");
    }
  };

  return (
    <Wrapper id="contact">
      <div style={{ margin: 10, padding: 24 }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Get in touch with us</h1>
            <p className="font13">
              At GPTF, we always look forward to your communications. Every day we receive new ideas and feedback that make us better.
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <StyledForm onSubmit={handleSubmit}>
                <FormGroup>
                  <label className="font13">Full Name:</label>
                  <StyledInput
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className="font20 extraBold"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <label className="font13">Email:</label>
                  <StyledInput
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="font20 extraBold"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <label className="font13">Subject:</label>
                  <StyledInput
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="font20 extraBold"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <label className="font13">Message:</label>
                  <StyledTextarea
                    rows="4"
                    cols="50"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="font20 extraBold"
                    required
                  />
                </FormGroup>

                <SubmitButtonWrapper>
                  <SubmitButton type="submit" value="Send Message" />
                </SubmitButtonWrapper>
              </StyledForm>
            </div>

            {/* Image section remains the same */}
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" style={{ width: "180px", height: "204px", objectFit: "cover" }} />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" style={{ width: "180px", height: "295px", objectFit: "cover" }} />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" style={{ width: "278px", height: "330px", objectFit: "cover" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin: 30px;

  @media (max-width: 860px) {
    margin: 10px; /* Reduce margin on smaller screens */
  }
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;

  @media (max-width: 860px) {
    text-align: center;
    padding: 40px 0; /* Reduce padding for mobile */
  }
`;

const StyledForm = styled.form`
  padding: 70px 0 30px 0;

  @media (max-width: 860px) {
    padding: 20px 0; /* Reduce padding for mobile */
  }
`;

const FormGroup = styled.div`
  margin-bottom: 25px;

  @media (max-width: 860px) {
    margin-bottom: 15px; /* Reduce margin for mobile */
  }
`;

const StyledInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #707070;
  padding: 8px 0;
  margin-top: 5px;
  color: #9bbb59;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-bottom-color: #9bbb59;
  }

  @media (max-width: 860px) {
    font-size: 14px; /* Smaller font size for mobile */
  }
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #707070;
  padding: 8px 0;
  margin-top: 5px;
  color: #9bbb59;
  font-size: 16px;
  min-height: 100px;
  outline: none;
  transition: border-color 0.3s ease;
  resize: vertical;

  &:focus {
    border-bottom-color: #9bbb59;
  }

  @media (max-width: 860px) {
    font-size: 14px; /* Smaller font size for mobile */
  }
`;

const SubmitButtonWrapper = styled.div`
  padding: 10px 0;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled.input.attrs({ type: 'submit' })`
  border: 2px solid #9bbb59;
  background-color: transparent;
  padding: 15px 25px;
  width: auto;
  min-width: 200px;
  color: #9bbb59;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(155, 187, 89, 0.1);

  &:hover {
    background-color: #9bbb59;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(155, 187, 89, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(155, 187, 89, 0.1);
  }

  @media (max-width: 860px) {
    font-size: 14px; /* Smaller font size for mobile */
    padding: 10px 15px; /* Reduce padding for mobile */
  }
`;

const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;

  @media (max-width: 860px) {
    max-width: 120px; /* Reduce image size for mobile */
    margin: 5px; /* Adjust margins for mobile */
  }
`;

// Add styles for the column layout in the image section
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 860px) {
    flex-direction: row; /* Change direction to row for mobile */
    justify-content: center; /* Center images */
  }
`;


import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import TopNavbar from "../../components/Nav/TopNavbar";
import { getArticles } from 'store/actions/cmsActions';
import { FaNewspaper } from "react-icons/fa";

const FullBlogPage = () => {
  const dispatch = useDispatch();
  const { articles } = useSelector((state) => state.cmsReducer);
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';

  useEffect(() => {
    dispatch(getArticles(organizationId));
  }, [dispatch]);

  return (
    <>
      <TopNavbar />
      <Header>
        <HeaderTitle>Game Products Trust Fund News</HeaderTitle>
        <HeaderSubtitle>
          Explore our latest articles on wildlife conservation, sustainable resource management,
          and community development initiatives in Namibia. Stay informed about the Game Products
          Trust Fund's impact on preserving biodiversity and supporting local communities.
        </HeaderSubtitle>
      </Header>
      <Wrapper>
        <Container>
          {articles && articles.length > 0 ? (
            <ArticleList>
              {articles.map((article) => (
                <ArticleItem key={article.id} as={Link} to={`/article/${article.id}`}>
                  <ArticleImage src={article.imageUrl} alt={article.title} />
                  <ArticleContent>
                    <ArticleTitle>{article.title}</ArticleTitle>
                    <ArticleDescription>{article.description}</ArticleDescription>
                    <ArticleFooter>
                      <ArticleDate>{new Date(article.createdAt).toLocaleDateString()}</ArticleDate>
                      <ReadMoreButton>Read More</ReadMoreButton>
                    </ArticleFooter>
                  </ArticleContent>
                </ArticleItem>
              ))}
            </ArticleList>
          ) : (
            <NoArticlesMessage>
              <FaNewspaper size={50} />
              <h2>No Articles Available</h2>
              <p>There are currently no articles to display. Please check back later for updates on our initiatives.</p>
            </NoArticlesMessage>
          )}
        </Container>
      </Wrapper>
      <Divider />
    </>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const Header = styled.header`
  position: relative;
  background-color: ${colors.light};
  color: ${colors.tertiary};
  padding-top: 5rem;
  padding-bottom: 2rem;
  text-align: center;
  top: 70px;
  border-bottom: 2px solid ${colors.primary};
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.tertiary};
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};
  max-width: 800px;
  margin: 0 auto;
`;

const Wrapper = styled.section`
  width: 100%;
  padding-top: 5rem;
  background: ${colors.light};
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ArticleList = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const ArticleItem = styled(Link)`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ArticleImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ArticleContent = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ArticleTitle = styled.h3`
  font-size: 1.25rem;
  color: ${colors.tertiary};
  margin-bottom: 0.5rem;
`;

const ArticleDescription = styled.p`
  font-size: 0.875rem;
  color: ${colors.neutral};
  margin-bottom: 1rem;
  flex-grow: 1;
`;

const ArticleFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ArticleDate = styled.span`
  font-size: 0.75rem;
  color: ${colors.neutral};
`;

const ReadMoreButton = styled.span`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${colors.primary};
  margin: 2rem 0;
`;

const NoArticlesMessage = styled.div`
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: ${colors.tertiary};
    margin: 1rem 0;
  }

  p {
    color: ${colors.neutral};
  }

  svg {
    color: ${colors.secondary};
  }
`;

export default FullBlogPage;

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const ContactCard = () => {
  const [formData, setFormData] = useState({ 
    fullName: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const handleChange = (e) => { 
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });  
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // API endpoint URL
    // const apiEndpoint = 'http://localhost:5001/api/custom-mails/gptf/gptf-contact-us';
    const apiEndpoint = 'https://qualis-email-server.vercel.app/api/api/custom-mails/gptf/gptf-contact-us';

    // Fetch options
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData) // Convert form data to JSON
    };

    // Make the request
    fetch(apiEndpoint, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        alert('Message sent successfully!');
        setFormData({
          fullName: '',
          email: '',
          subject: '',
          message: ''
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error occurred while sending the message. Please try again later.');
      });
  };
  

  return (
    <Card>
      <h2>Get in Touch</h2>
      <ContactForm onSubmit={handleSubmit}>
        <Input 
          type="text" 
          name="fullName" 
          placeholder="Your Name" 
          value={formData.fullName} 
          onChange={handleChange} 
          required 
        />
        <Input 
          type="email" 
          name="email" 
          placeholder="Your Email" 
          value={formData.email} 
          onChange={handleChange} 
          required 
        />
        <Input 
          type="text" 
          name="subject" 
          placeholder="Subject" 
          value={formData.subject} 
          onChange={handleChange} 
          required 
        />
        <TextArea 
          name="message" 
          placeholder="Your Message" 
          value={formData.message} 
          onChange={handleChange} 
          required 
        />
        <Button type="submit">Send Message</Button>
      </ContactForm>
      <ContactInfo>
        <p><strong>Address:</strong> 7574 Marais Street, Windhoek</p>
        <p><strong>Phone:</strong> (+264) 061 240 100</p>
        <p><strong>Email:</strong> info@gptf.org.na</p>
        <p><strong>Hours:</strong> Mon - Fri: 8am - 5pm</p>
      </ContactInfo>
    </Card>
  );
};

const Card = styled.div`
  background: #fff;
  border: 2px solid #9bbb59;
  padding: 20px;
  width: 50%;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transform: perspective(800px) rotateX(5deg) rotateY(-5deg);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: perspective(800px) rotateX(0deg) rotateY(0deg);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(155, 187, 89, 0.3), rgba(255, 255, 255, 0));
    z-index: -1;
  }

  h2 {
    color: #9bbb59;
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #9bbb59;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const TextArea = styled.textarea`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #9bbb59;
  border-radius: 5px;
  height: 100px;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  background-color: #9bbb59;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #006666;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
`;

const ContactInfo = styled.div`
  margin-top: 20px;
  font-size: 0.9em;
`;

export default ContactCard;

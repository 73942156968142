import React, { useState } from 'react';
import styled from 'styled-components';

const DownloadFormModal = ({ onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    surname: '',
    firstNames: '',
    businessName: '',
    emailAddress: '',
    cellNo: '',
    landline: '',
    TenderNumber: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation can be added here
    onSubmit(formData);
  };
  

  return (
    <ModalOverlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>X</CloseButton>
        <FormTitle>Provide Your Details</FormTitle>
        <FormSubtitle>
          Message: For the Public Entity to communicate clarifications or modifications to bidders as per Regulation 34 (3),
          all bidders must provide full correct and accurate contact details.
        </FormSubtitle>
        <Form onSubmit={handleSubmit}>
          <InputField
            type="text"
            name="surname"
            placeholder="Surname"
            value={formData.surname}
            onChange={handleChange}
            required
          />
          <InputField
            type="text"
            name="firstNames"
            placeholder="First Names"
            value={formData.firstNames}
            onChange={handleChange}
            required
          />
          <InputField
            type="text"
            name="businessName"
            placeholder="Business Name"
            value={formData.businessName}
            onChange={handleChange}
            required
          />
          <InputField
            type="email"
            name="emailAddress"
            placeholder="Email Address"
            value={formData.emailAddress}
            onChange={handleChange}
            required
          />
          <InputField
            type="text"
            name="TenderNumber"
            placeholder="Procurement Reference No"
            value={formData.TenderNumber}
            onChange={handleChange}
          />                    
          <InputField
            type="text"
            name="cellNo"
            placeholder="Cell No (Optional)"
            value={formData.cellNo}
            onChange={handleChange}
          />
          <InputField
            type="text"
            name="landline"
            placeholder="Landline (Optional)"
            value={formData.landline}
            onChange={handleChange}
          />
          <SubmitButton type="submit">Download</SubmitButton>
        </Form>
      </ModalContainer>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  float: right;
  cursor: pointer;
`;

const FormTitle = styled.h2`
  margin-bottom: 10px;
  color: #8c9c6c;
`;

const FormSubtitle = styled.p`
  margin-bottom: 20px;
  font-size: 0.9rem;
  color: #555;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputField = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: #8c9c6c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #667140;
  }
`;

export default DownloadFormModal;

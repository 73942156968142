import React from "react";
import styled from "styled-components";
import HeaderBg from "../../assets/img/desert-safari.avif";  // You can use the same background image

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const OurHistory = () => {
  return (
    <>
      <Header>
        <HeaderTitle>Our History</HeaderTitle>
        <HeaderSubtitle>Game Products Trust Fund (GPTF)</HeaderSubtitle>
      </Header>
      <Wrapper>
        <Container>
          <Section>
            <SectionTitle>About the GPTF</SectionTitle>
            <Paragraph>
              The Game Products Trust Fund (GPTF) was established through the Game Products Trust Fund Act (Act No. 7 of 1997) as a mechanism for ensuring that revenue obtained from the sale of wildlife products could be used exclusively towards wildlife conservation and community conservation and development programmes aimed at harmonizing the co-existence of people with such wildlife, and thus securing a future for wildlife outside of and within protected areas in Namibia.
            </Paragraph>
          </Section>

          <Section>
            <SectionTitle>Our Mandate</SectionTitle>
            <Paragraph>
              The GPTF is mandated by an act of parliament to collect revenue from wildlife and wildlife products recovered on state land and reinvest it into wildlife conservation, communal conservation and rural development programmes.
            </Paragraph>
          </Section>

          <Section>
            <SectionTitle>Our Mission</SectionTitle>
            <Paragraph>
              To reinvest funds from wildlife-use and other sources into sustainable conservation programmes and livelihoods.
            </Paragraph>
          </Section>

          <Section>
            <SectionTitle>Our Vision</SectionTitle>
            <Paragraph>
              To be a catalyst for sustainable wildlife management practices while improving livelihoods of those sharing their land with wildlife.
            </Paragraph>
          </Section>

          <Section>
            <SectionTitle>Our Objectives</SectionTitle>
            <ObjectiveList>
              <ObjectiveItem>
                To make grants to emerging conservancies and wildlife councils for implementing and maintaining approved projects and programmes.
              </ObjectiveItem>
              <ObjectiveItem>
                To allocate funds to conservancies, wildlife councils, protected areas, and approved persons, organisations, and institutions.
              </ObjectiveItem>
              <ObjectiveItem>
                To support measures aimed at improving the relationship between people and wildlife.
              </ObjectiveItem>
              <ObjectiveItem>
                To support improvements in the monitoring, management, protection, sustainable use, and development of wildlife resources in rural areas.
              </ObjectiveItem>
            </ObjectiveList>
          </Section>
        </Container>
      </Wrapper>
    </>
  );
};

const Header = styled.header`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  color: white;
  padding: 4rem 0;
  text-align: center;
  margin-top: 6rem;
`;

const HeaderTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeaderSubtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 4rem 0;
  background: ${colors.light};
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Section = styled.div`
  margin-bottom: 4rem;
`;

const SectionTitle = styled.h3`
  font-size: 2rem;
  color: ${colors.tertiary};
  margin-bottom: 1.5rem;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: ${colors.secondary};
  }
`;

const Paragraph = styled.p`
  line-height: 1.6;
  color: ${colors.neutral};
`;

const ObjectiveList = styled.ul`
  padding-left: 1.5rem;
`;

const ObjectiveItem = styled.li`
  color: ${colors.neutral};
  margin-bottom: 1rem;
  line-height: 1.6;
  
  &::marker {
    color: ${colors.secondary};
  }
`;

export default OurHistory;

import React from "react";
import styled from "styled-components";
import { FaCheckCircle } from 'react-icons/fa';

const CompletedProjectsPage = () => {
  const completedProjects = [
    // {
    //   name: "Wildlife Habitat Restoration",
    //   description: "A project focused on restoring degraded wildlife habitats across the region.",
    //   completionDate: "July 2023"
    // },
    // {
    //   name: "Community Clean Water Initiative",
    //   description: "Provided clean water access to over 5,000 residents in rural communities.",
    //   completionDate: "March 2024"
    // },
    // Add more completed projects as needed
  ];

  return (
    <>
      <Header>
        <HeaderTitle>Completed Projects</HeaderTitle>
        <HeaderSubtitle>
          Explore our successfully completed conservation and community development projects.
        </HeaderSubtitle>
      </Header>
      <Wrapper>
        <Container>
          {completedProjects.length > 0 ? (
            <ProjectsGrid>
              {completedProjects.map((project, index) => (
                <ProjectCard key={index}>
                  <ProjectIcon>
                    <FaCheckCircle size={30} />
                  </ProjectIcon>
                  <ProjectDetails>
                    <ProjectName>{project.name}</ProjectName>
                    <ProjectDescription>{project.description}</ProjectDescription>
                    <ProjectDate>Completed on: {project.completionDate}</ProjectDate>
                  </ProjectDetails>
                </ProjectCard>
              ))}
            </ProjectsGrid>
          ) : (
            <NoCompletedProjectsMessage>
              <FaCheckCircle size={50} />
              <h2>No Completed Projects Yet</h2>
              <p>We're working hard on our ongoing projects. Check back soon to see our completed initiatives.</p>
            </NoCompletedProjectsMessage>
          )}
        </Container>
      </Wrapper>
    </>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const Header = styled.header`
  background-color: ${colors.light};
  color: ${colors.tertiary};
  padding-top: 7rem;
  padding-bottom: 2rem;
  text-align: center;
  margin-top: 5rem;
  border-bottom: 2px solid ${colors.primary};
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.tertiary};
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};
  max-width: 800px;
  margin: 0 auto;
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 2rem 0;
  background: ${colors.light};
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const NoCompletedProjectsMessage = styled.div`
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: ${colors.tertiary};
    margin: 1rem 0;
  }

  p {
    color: ${colors.neutral};
  }

  svg {
    color: ${colors.secondary};
  }
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ProjectCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`;

const ProjectIcon = styled.div`
  margin-right: 1rem;
  svg {
    color: ${colors.secondary};
  }
`;

const ProjectDetails = styled.div`
  h3 {
    color: ${colors.tertiary};
    margin-bottom: 0.5rem;
  }

  p {
    color: ${colors.neutral};
  }
`;

const ProjectName = styled.h3`
  font-size: 1.5rem;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  margin: 0.5rem 0;
`;

const ProjectDate = styled.p`
  font-size: 0.9rem;
  color: ${colors.neutral};
`;

export default CompletedProjectsPage;

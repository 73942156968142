const testimonials = [
    {
      id: 1,
      attributes: {
        clientFullName: "Mr. Matheus Mulundu",
        Testimonial: [
          {
            children: [
              {
                text: "The Game Products Trust Fund has made a significant impact on our community. Their commitment to wildlife conservation and rural development is truly commendable.",
              },
            ],
          },
        ],
      },
    },
    {
      id: 2,
      attributes: {
        clientFullName: "Ms. Anny Hamata",
        Testimonial: [
          {
            children: [
              {
                text: "Working with the Game Products Trust Fund has been a rewarding experience. Their initiatives are well-organized and make a real difference.",
              },
            ],
          },
        ],
      },
    },
    {
      id: 3,
      attributes: {
        clientFullName: "Mr. Victor Nzaramba",
        Testimonial: [
          {
            children: [
              {
                text: "I appreciate the transparency and dedication of the Game Products Trust Fund. Their efforts in promoting sustainable development are remarkable.",
              },
            ],
          },
        ],
      },
    },
  ];
  
  export default testimonials;
  
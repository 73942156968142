import React from "react";
import styled from "styled-components";
import HeaderBg from "../../assets/img/desert-safari.avif";  // You can use the same background image
import { FaBalanceScale, FaHandshake, FaLeaf, FaChartLine } from 'react-icons/fa';

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const OurValues = () => {
  return (
    <>
      <Header>
        <HeaderTitle>Our Values</HeaderTitle>
        <HeaderSubtitle>Game Products Trust Fund (GPTF)</HeaderSubtitle>
      </Header>
      <Wrapper>
        <Container>
          <Section>
            <SectionTitle>Core Values</SectionTitle>
            <ValuesList>
              <ValueItem>
                <FaBalanceScale /> Accountability: We strive to remain accountable to all our stakeholders.
              </ValueItem>
              <ValueItem>
                <FaHandshake /> Integrity: What we profess is what we do.
              </ValueItem>
              <ValueItem>
                <FaLeaf /> Commitment: We are committed to sustainable wildlife practices and improving livelihoods.
              </ValueItem>
              <ValueItem>
                <FaChartLine /> Transparency: We are transparent in all our transactions with our stakeholders.
              </ValueItem>
              <ValueItem>
                <FaHandshake /> Improvement: We strive to improve the livelihoods of people that share their land with wildlife.
              </ValueItem>
            </ValuesList>
          </Section>
        </Container>
      </Wrapper>
    </>
  );
};

const Header = styled.header`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  color: white;
  padding: 4rem 0;
  text-align: center;
  margin-top: 6rem;
`;

const HeaderTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeaderSubtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 4rem 0;
  background: ${colors.light};
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Section = styled.div`
  margin-bottom: 4rem;
`;

const SectionTitle = styled.h3`
  font-size: 2rem;
  color: ${colors.tertiary};
  margin-bottom: 1.5rem;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: ${colors.secondary};
  }
`;

const ValuesList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ValueItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: ${colors.neutral};
  
  svg {
    color: ${colors.secondary};
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`;

export default OurValues;

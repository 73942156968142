import React from "react";
import styled from "styled-components";
import { FaGraduationCap } from 'react-icons/fa';

const BursariesPage = () => {
  return (
    <>
      <Header>
        <HeaderTitle>Bursary Opportunities</HeaderTitle>
        <HeaderSubtitle>
          Discover educational funding opportunities to support your academic journey.
        </HeaderSubtitle>
      </Header>
      <Wrapper>
        <Container>
          <NoBursariesMessage>
            <FaGraduationCap size={50} />
            <h2>No Current Bursaries</h2>
            <p>We don't have any bursary opportunities available at the moment. Please check back later for future offerings.</p>
          </NoBursariesMessage>
        </Container>
      </Wrapper>
    </>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const Header = styled.header`
  background-color: ${colors.light};
  color: ${colors.tertiary};
  padding-top: 5rem;
  text-align: center;
  margin-top: 5rem;
  border-bottom: 2px solid ${colors.primary};
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.tertiary};
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};
  max-width: 800px;
  margin: 0 auto;
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 2rem 0;
  background: ${colors.light};
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const NoBursariesMessage = styled.div`
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: ${colors.tertiary};
    margin: 1rem 0;
  }

  p {
    color: ${colors.neutral};
  }

  svg {
    color: ${colors.secondary};
  }
`;

export default BursariesPage;
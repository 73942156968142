import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaDownload, FaFolderOpen } from 'react-icons/fa';

const DownloadsPage = () => {
  const [content, setContent] = useState([]);
  const [filter, setFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    // Generate dummy content
    const dummyContent = [
      ...generateDummyContent('Video', 0), // Set count to 0 for testing "No Downloads"
      ...generateDummyContent('Document', 0), // Set count to 0 for testing "No Downloads"
      ...generateDummyContent('Image', 0), // Set count to 0 for testing "No Downloads"
    ];
    setContent(dummyContent);
  }, []);

  const generateDummyContent = (type, count) => {
    return Array.from({ length: count }, (_, index) => ({
      id: `${type}-${index + 1}`,
      title: `${type} Title ${index + 1}`,
      description: `This is a description of ${type.toLowerCase()} ${index + 1}.`,
      size: `${(Math.random() * 100 + 10).toFixed(2)} MB`,
      type,
    }));
  };

  const filteredContent = content.filter(item => filter === 'All' || item.type === filter);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredContent.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredContent.length / itemsPerPage);

  return (
    <Wrapper>
      <Header>
        <HeaderTitle>Downloads</HeaderTitle>
        <HeaderSubtitle>Select and download your files</HeaderSubtitle>
        <FilterContainer>
          <FilterButton onClick={() => setFilter('All')} active={filter === 'All'}>All</FilterButton>
          <FilterButton onClick={() => setFilter('Video')} active={filter === 'Video'}>Videos</FilterButton>
          <FilterButton onClick={() => setFilter('Document')} active={filter === 'Document'}>Documents</FilterButton>
          <FilterButton onClick={() => setFilter('Image')} active={filter === 'Image'}>Images</FilterButton>
        </FilterContainer>
      </Header>
      <ContentSection>
        {currentItems.length > 0 ? (
          <>
            <ContentTable>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Size</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map(item => (
                  <tr key={item.id}>
                    <td>{item.title}</td>
                    <td>{item.description}</td>
                    <td>{item.type}</td>
                    <td>{item.size}</td>
                    <td>
                      <DownloadButton>
                        <FaDownload /> Download
                      </DownloadButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </ContentTable>
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => (
                <PageButton
                  key={i}
                  onClick={() => paginate(i + 1)}
                  active={currentPage === i + 1}
                >
                  {i + 1}
                </PageButton>
              ))}
            </Pagination>
          </>
        ) : (
          <NoDownloadsMessage>
            <FaFolderOpen size={50} />
            <h2>No Downloads Available</h2>
            <p>There are currently no files available for download. Please check back later.</p>
          </NoDownloadsMessage>
        )}
      </ContentSection>
    </Wrapper>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const Wrapper = styled.div`
  background-color: ${colors.light};
  min-height: 100vh;
  padding: 40px;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 40px;
  margin-top: 5rem;
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  color: ${colors.tertiary};
  margin-bottom: 10px;
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const FilterButton = styled.button`
  background-color: ${props => props.active ? colors.primary : 'white'};
  color: ${props => props.active ? 'white' : colors.primary};
  border: 1px solid ${colors.primary};
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
`;

const ContentSection = styled.section`
  margin-top: 40px;
`;

const ContentTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid ${colors.light};
  }

  th {
    background-color: ${colors.primary};
    color: white;
  }

  tr:nth-child(even) {
    background-color: ${colors.light};
  }
`;

const DownloadButton = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background-color: ${props => props.active ? colors.primary : 'white'};
  color: ${props => props.active ? 'white' : colors.primary};
  border: 1px solid ${colors.primary};
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
`;

const NoDownloadsMessage = styled.div`
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: ${colors.tertiary};
    margin: 1rem 0;
  }

  p {
    color: ${colors.neutral};
  }

  svg {
    color: ${colors.secondary};
  }
`;

export default DownloadsPage;

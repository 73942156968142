import React from "react";
import styled from "styled-components";
import HeaderBg from "../../assets/img/desert-safari.avif";  
import { FaBalanceScale, FaHandshake, FaLeaf, FaChartLine } from 'react-icons/fa';

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const Disclaimer = () => {
  return (
    <>
      <Header>
        <HeaderTitle>Disclaimer & Privacy Policy</HeaderTitle>
      </Header>
      <Section>
          {/* <Title>Disclaimer & Privacy Policy</Title>   */}
          <Content>
            The Game Products Trust Fund has carefully tried to make sure that the information on this website is accurate at the time of its inclusion. However, it cannot be held liable or accept responsibility for any loss or damage that may arise from any errors or omissions appearing on this website. Any user who relies or uses such erroneous information should know that they will be doing so entirely at their own risk. Links from this site to other sites do not imply any endorsement either of the website supplier or its content. The Game Products Trust Fund carries no responsibility for the accuracy of any information on the sites concerned.
          </Content>
        </Section>
    </>
  );
};

const Header = styled.header`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  color: white;
  padding: 4rem 0;
  text-align: center;
  margin-top: 6rem;
`;

const HeaderTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;


const Section = styled.section`
  margin-bottom: 2rem;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: ${colors.primary};
  margin-bottom: 1rem;
  border-bottom: 2px solid ${colors.primary};
  padding-bottom: 0.5rem;
`;

const Content = styled.div`
  font-size: 1rem;
  color: ${colors.neutral};
  line-height: 1.6;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;

  &:before {
    content: '•';
    position: absolute;
    left: 0;
    color: ${colors.primary};
  }
`;

const StyledLink = styled.a`
  color: ${colors.primary};
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${colors.secondary};
    text-decoration: underline;
  }
`;

export default Disclaimer;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { getProjects } from "store/actions/cmsActions";
import { FaList, FaThLarge, FaDownload, FaProjectDiagram } from 'react-icons/fa';

const ProjectsPage = () => {
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.cmsReducer);
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';
  const [viewMode, setViewMode] = useState('grid');

  useEffect(() => {
    dispatch(getProjects(organizationId));
    console.log(projects)
  }, [dispatch]);

  const toggleViewMode = () => {
    setViewMode(prevMode => prevMode === 'grid' ? 'list' : 'grid');
  };

  return (
    <>
      <Header>
        <HeaderTitle>Our Awesome Projects</HeaderTitle>
        <HeaderSubtitle>
          Explore our range of conservation and community development projects across Namibia.
          These initiatives showcase our commitment to wildlife protection and sustainable resource management.
        </HeaderSubtitle>
      </Header>
      <Wrapper>
        <Container>
          {projects && projects.length > 0 ? (
            <>
              <ControlsContainer>
                <ViewToggle onClick={toggleViewMode}>
                  {viewMode === 'grid' ? <FaList /> : <FaThLarge />}
                  {viewMode === 'grid' ? ' List View' : ' Grid View'}
                </ViewToggle>
              </ControlsContainer>
              {viewMode === 'grid' ? (
                <ProjectGrid>
                  {projects.map((project, index) => (
                    <ProjectCard key={index}>
                      <ProjectImage src={project.imageUrl} alt={project.title} />
                      <ProjectContent>
                        <ProjectTitle>{project.title}</ProjectTitle>
                        <ProjectDescription>{project.description}</ProjectDescription>
                        <DownloadButton>
                          <FaDownload /> Download
                        </DownloadButton>
                        <ProjectInfo>
                          <span>By: {project.author || 'Unknown'}</span>
                          <span>{project.publishDate || 'Unknown date'}</span>
                        </ProjectInfo>
                      </ProjectContent>
                    </ProjectCard>
                  ))}
                </ProjectGrid>
              ) : (
                <ProjectTable>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Download</th>
                      <th>Created By</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.map((project, index) => (
                      <tr key={index}>
                        <td>{project.title}</td>
                        <td>{project.description}</td>
                        <td>
                          <DownloadButton>
                            <FaDownload /> Download
                          </DownloadButton>
                        </td>
                        <td>{project.author || 'Unknown'}</td>
                        <td>{project.publishDate || 'Unknown date'}</td>
                      </tr>
                    ))}
                  </tbody>
                </ProjectTable>
              )}
            </>
          ) : (
            <NoProjectsMessage>
              <FaProjectDiagram size={50} />
              <h2>No Projects Available</h2>
              <p>There are currently no projects to display. Please check back later for updates on our initiatives.</p>
            </NoProjectsMessage>
          )}
        </Container>
      </Wrapper>
    </>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};


const Header = styled.header`
  background-color: ${colors.light};
  color: ${colors.tertiary};
  padding-top: 7rem;
  padding-bottom: 2rem;
  text-align: center;
  margin-top: 5rem;
  border-bottom: 2px solid ${colors.primary};
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.tertiary};
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};
  max-width: 800px;
  margin: 0 auto;
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 2rem 0;
  background: ${colors.light};
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const ViewToggle = styled.button`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const ProjectCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ProjectContent = styled.div`
  padding: 1rem;
`;

const ProjectTitle = styled.h3`
  font-size: 1.25rem;
  color: ${colors.tertiary};
  margin-bottom: 0.5rem;
`;

const ProjectDescription = styled.p`
  color: ${colors.neutral};
  margin-bottom: 1rem;
  font-size: 0.9rem;
`;

const DownloadButton = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const ProjectInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  color: ${colors.neutral};
  margin-top: 1rem;
`;

const ProjectTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid ${colors.light};
  }

  th {
    background-color: ${colors.primary};
    color: white;
  }

  tr:nth-child(even) {
    background-color: ${colors.light};
  }
`;

const NoProjectsMessage = styled.div`
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: ${colors.tertiary};
    margin: 1rem 0;
  }

  p {
    color: ${colors.neutral};
  }

  svg {
    color: ${colors.secondary};
  }
`;

export default ProjectsPage;
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link as RouterLink, useLocation } from "react-router-dom";
import LogoImage from "../../assets/img/logo.png";
import { X, Menu, ChevronDown, ChevronRight, Target } from "lucide-react";

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: -10px;
  left: 0;
  z-index: 9999;
  background: white;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  box-shadow: ${({ y }) => (y > 100 ? "0 4px 10px rgba(0, 0, 0, 0.1)" : "none")};
  transform: ${({ y }) => (y > 100 ? "translateY(0)" : "translateY(10px)")};
  // border-radius: 8px;
  padding: 10px 0;
`;

const NavInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ y }) => (y > 100 ? "60px" : "80px")};
  transition: height 0.3s ease-in-out;
`;


const LogoImg = styled.img`
  height: 100px; 
  position: absolute;
  top: -1px; 
  margin-top: 12px;
  border: 0.5px solid #61743c;
  border-radius: 12px;
  z-index: 1;
`;


const BurgerWrapper = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 960px) {
    display: block;
  }
`;

const NavMenuWrapper = styled.div`
  @media (max-width: 960px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: white;
    z-index: 999;
    padding: 20px;
    // border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
`;

const NavMenu = styled.ul`
  display: flex;
  list-style: none;
  background-color: #60743c;
  padding: 0; /* Ensure no padding */
  margin: 0; /* Ensure no margin */
  border-radius: 8px;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 0; /* Ensure no padding on mobile */
    margin: 0; /* Ensure no margin on mobile */
    align-items: flex-start; /* Align items to the start */
  }
`;


const NavItem = styled.li`
  margin: 0; /* Remove horizontal margin */
  padding: 10px 0; /* Add vertical padding for spacing */
  width: 100%; /* Ensure items take full width */
  color: white;

  @media (max-width: 960px) {
    margin: 0; /* Ensure no margin on mobile */
    width: auto; /* Reset width to auto for alignment */
  }
`;


const NavLink = styled(RouterLink)`
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align content to the left */

  &:hover {
    color: #333;
  }
`;

const DropdownItem = styled.div`
  position: relative;
  cursor: pointer;

`;

const DropdownContent = styled.ul`
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #60743c;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 200px;
  padding: 10px 0;
  list-style-type: none;
  border-radius: 8px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};

  @media (max-width: 960px) {
    position: static;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    margin-top: 10px;
  }
`;

const DropdownLink = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align content with space between */
`;


const ChevronIcon = styled(ChevronDown)`
  margin-left: 5px;
`;

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setIsMobileMenuOpen(false);
    setOpenDropdowns([]);
  }, [location]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const toggleDropdown = (dropdown, isTopLevel = false) => {
    setOpenDropdowns((prevOpen) => {
      if (isTopLevel) {
        return prevOpen.includes(dropdown) ? prevOpen.filter((item) => item !== dropdown) : [dropdown];
      } else {
        return prevOpen.includes(dropdown) ? prevOpen.filter((item) => item !== dropdown) : [...prevOpen, dropdown];
      }
    });
  };

  const navItems = [
    { label: "Home", to: "/" },
    {
      label: "About",
      dropdown: "about",
      items: [
        { label: "Mission, Vision & Values", to: "/about-mission" },
        // { label: "Values", to: "/about-values" },
        {
          label: "Organogram", // or "Organizational Structure"
          dropdown: "organogram",
          items: [
            { label: "Board Of Directors", to: "/board-of-directors" },
            { label: "Executive Committee", to: "/executive-committee" },
            { label: "Technical Advisory Group", to: "/technical-advisory" },
            { label: "Finance, Risk & Audit Committee", to: "/risk-audit" },
            { label: "Staff", to: "/staff" },
          ],
        },        
        { label: "Disclaimer", to: "/disclaimer" },
        { label: "FAQs", to: "/faqs" },
      ],
    },
    {
      label: "Projects",
      dropdown: "projects",
      items: [
        { label: "Projects", to: "/projects" },
        { label: "Funding Guidelines", to: "/funding-guidelines" },
        { label: "Funded Already", to: "/funded-projects" },
        { label: "How to Get Funded", to: "/getting-funded" },
        { label: "Completed Projects", to: "/completed-projects" },
      ],
    },
    {
      label: "Procurement",
      dropdown: "procurement",
      items: [
        { label: "Open Bids", to: "/procurement-bids" },
        { label: "Awarded Bids", to: "/procurement-awards" },
        { label: "Annual Procurement Plan", to: "/procurement-plan" },
        { label: "Legislation", to: "/procurement-legislation" },
        { label: "General Conditions Of Contact", to: "/procurement-conditions" },
        { label: "Debarred Suppliers", to: "https://egp2.gov.na/forms/SearchSuspendedBidders.jsf" },
      ],
    },
    {
      label: "Notices",
      dropdown: "notices",
      items: [
        { label: "Vacancies", to: "/vacancies" },
        { label: "Bursaries", to: "/bursaries" },
        { label: "Useful Links", to: "/useful-links" },
      ],
    },
    { label: "Downloads", to: "/downloads" },
    {
      label: "Media",
      dropdown: "mediaReleases",
      items: [
        { label: "Media", to: "/blogs" },
        { label: "Photo Gallery", to: "/photo-gallery" },
        { label: "Videos", to: "/video-gallery" },
      ],
    },
    { label: "Contact", to: "/contact" },
  ];

  const renderDropdownContent = (items, parentDropdown, isTopLevel = false) => (
    <DropdownContent isOpen={openDropdowns.includes(parentDropdown)}>
      {items.map((item, index) => (
        <NavItem key={index}>
          {item.items ? (
            <DropdownItem>
              <DropdownLink onClick={() => toggleDropdown(parentDropdown + index, !isTopLevel)}>
                {item.label}
                {item.items && <ChevronIcon />}
              </DropdownLink>
              {renderDropdownContent(item.items, parentDropdown + index)}
            </DropdownItem>
          ) : (
            <NavLink to={item.to} onClick={() => setIsMobileMenuOpen(false)}>
              {item.label}
            </NavLink>
          )}
        </NavItem>
      ))}
    </DropdownContent>
  );

  return (
    <Wrapper y={y}>
      <NavInner y={y}>
        <RouterLink to="/">
          <LogoImg src={LogoImage} alt="Logo" />
        </RouterLink>
        <BurgerWrapper onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <X size={30} /> : <Menu size={30} />}
        </BurgerWrapper>
        <NavMenuWrapper isOpen={isMobileMenuOpen}>
          <NavMenu>
            {navItems.map((item, index) => (
              <NavItem key={index}>
                {item.items ? (
                  <DropdownItem>
                    <DropdownLink onClick={() => toggleDropdown(item.dropdown, true)}>
                      {item.label}
                      {item.items && <ChevronIcon />}
                    </DropdownLink>
                    {renderDropdownContent(item.items, item.dropdown, true)}
                  </DropdownItem>
                ) : (
                  <NavLink to={item.to} onClick={() => setIsMobileMenuOpen(false)}>
                    {item.label}
                  </NavLink>
                )}
              </NavItem>
            ))}
          </NavMenu>
        </NavMenuWrapper>
      </NavInner>
    </Wrapper>
  );
}

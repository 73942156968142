import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TopNavbar from "../../components/Nav/TopNavbar";
import { createClient } from 'pexels';

const client = createClient("ULz1fxxR1RyQKCJPV5ooNOM9Hwp5mjNJB49fHaDSmXSBHeHBcV0TzwUI");

const VideoGallery = () => {
    const [videos, setVideos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [modalVideo, setModalVideo] = useState(null);
    const [modalIndex, setModalIndex] = useState(null);
    const videosPerPage = 9;

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await client.videos.popular({ per_page: 30 });
                if (response.videos.length > 0) {
                    // setVideos(response.videos);
                    setVideos([]);
                } else {
                    setVideos([]);
                }
            } catch (error) {
                console.error("Error fetching videos:", error);
                setVideos([]);  // Set videos to empty array on error
            }
        };

        fetchVideos();
    }, []);

    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const openModal = (index) => {
        setModalVideo(currentVideos[index]);
        setModalIndex(index);
    };

    const closeModal = () => {
        setModalVideo(null);
        setModalIndex(null);
    };

    const nextVideo = () => {
        if (modalIndex < currentVideos.length - 1) {
            setModalIndex(modalIndex + 1);
            setModalVideo(currentVideos[modalIndex + 1]);
        }
    };

    const prevVideo = () => {
        if (modalIndex > 0) {
            setModalIndex(modalIndex - 1);
            setModalVideo(currentVideos[modalIndex - 1]);
        }
    };

    return (
        <>
            <TopNavbar />
            <Wrapper>
                <Header>
                    <HeaderTitle>Video Gallery</HeaderTitle>
                    <HeaderSubtitle>Explore our collection of captivating videos</HeaderSubtitle>
                </Header>
                {currentVideos.length > 0 ? (
                    <GalleryGrid>
                        {currentVideos.map((video, index) => (
                            <VideoCard key={video.id} onClick={() => openModal(index)}>
                                <Thumbnail src={video.image} alt={video.url} />
                                <VideoInfo>
                                    <VideoTitle>Video by {video.user.name}</VideoTitle>
                                </VideoInfo>
                            </VideoCard>
                        ))}
                    </GalleryGrid>
                ) : (
                    <PlaceholderWrapper>
                        <PlaceholderText>No videos available</PlaceholderText>
                    </PlaceholderWrapper>
                )}
                <Pagination>
                    {Array.from({ length: Math.ceil(videos.length / videosPerPage) }, (_, i) => (
                        <PageButton
                            key={i}
                            onClick={() => paginate(i + 1)}
                            active={currentPage === i + 1}
                        >
                            {i + 1}
                        </PageButton>
                    ))}
                </Pagination>

                {modalVideo && (
                    <ModalOverlay onClick={closeModal}>
                        <ModalContent onClick={e => e.stopPropagation()}>
                            <ModalVideo controls>
                                <source src={modalVideo.video_files[0].link} type="video/mp4" />
                                Your browser does not support the video tag.
                            </ModalVideo>
                            <ModalDescription>
                                Video by {modalVideo.user.name}
                            </ModalDescription>
                            <ModalControls>
                                <ControlButton onClick={prevVideo} disabled={modalIndex === 0}>&lt; Previous</ControlButton>
                                <ControlButton onClick={nextVideo} disabled={modalIndex === currentVideos.length - 1}>Next &gt;</ControlButton>
                            </ModalControls>
                        </ModalContent>
                    </ModalOverlay>
                )}
            </Wrapper>
        </>
    );
};

const colors = {
    primary: '#8c9c6c',
    secondary: '#a59650',
    tertiary: '#667140',
    neutral: '#827c63',
    light: '#f5f5f5'
};

const Wrapper = styled.div`
    background-color: ${colors.light};
    min-height: 100vh;
    padding: 40px;
    margin-top: 70px;
`;

const Header = styled.header`
    text-align: center;
    margin-bottom: 40px;
    padding-top: 5rem;    
`;

const HeaderTitle = styled.h1`
    font-size: 2.5rem;
    color: ${colors.tertiary};
    margin-bottom: 10px;
`;

const HeaderSubtitle = styled.p`
    font-size: 1rem;
    color: ${colors.neutral};
`;

const GalleryGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
`;

const VideoCard = styled.div`
    border: 1px solid ${colors.neutral};
    border-radius: 4px;
    overflow: hidden;
    background: ${colors.light};
    cursor: pointer;
    padding: 5px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.02);
    }
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

const VideoInfo = styled.div`
    padding: 10px;
`;

const VideoTitle = styled.p`
    font-size: 0.9rem;
    color: ${colors.neutral};
    margin: 0;
`;

const PlaceholderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    text-align: center;
`;

const PlaceholderText = styled.div`
    font-size: 2rem;
    color: ${colors.neutral};
    background-color: ${colors.light};
    padding: 20px;
    border: 1px solid ${colors.neutral};
    border-radius: 8px;
`;

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`;

const PageButton = styled.button`
    background-color: ${props => props.active ? colors.primary : 'white'};
    color: ${props => props.active ? 'white' : colors.primary};
    border: 1px solid ${colors.primary};
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: ${colors.primary};
        color: white;
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background: white;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    max-width: 80%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ModalVideo = styled.video`
    width: 100%;
    height: auto;
    max-height: 60vh;
`;

const ModalDescription = styled.div`
    padding: 15px;
    font-size: 1rem;
    color: ${colors.neutral};
    text-align: center;
`;

const ModalControls = styled.div`
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
`;

const ControlButton = styled.button`
    background: ${colors.primary};
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;

    &:disabled {
        background: ${colors.neutral};
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        background: ${colors.secondary};
    }
`;

export default VideoGallery;

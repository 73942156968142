import React from "react";
import styled, { keyframes } from "styled-components";
import { FaQuestionCircle } from "react-icons/fa";
import HeaderBg from "../../assets/img/desert-safari.avif";

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const FAQPage = () => {
  const faqs = [
    {
      question: "What is the Game Products Trust Fund (GPTF)?",
      answer: "The GPTF is a fund established under the Game Products Trust Fund Act (Act No. 7 of 1997) to ensure that revenue from the sale of wildlife products is used exclusively for wildlife conservation and community conservation programs in Namibia."
    },
    {
      question: "How can I apply for funding from the GPTF?",
      answer: "Applications for funding can be submitted through our website or directly to the GPTF office. The application must meet the criteria outlined in our Funding Guidelines."
    },
    {
      question: "What types of projects are eligible for GPTF funding?",
      answer: "Eligible projects include those related to wildlife conservation, community conservation, and rural development. Projects must align with the GPTF's objectives and be approved by the Board in consultation with the Minister of Environment and Tourism."
    },
    {
      question: "How are GPTF funds allocated?",
      answer: "Funds are allocated to conservancies, wildlife councils, protected areas, and other approved entities. Allocation is based on the project’s relevance to wildlife conservation and community development."
    },
    {
      question: "What is the process for grant approval?",
      answer: "All grant applications are reviewed by the GPTF Board and must be approved by the Minister of Environment and Tourism. Successful applicants will be notified and funds will be disbursed according to the terms of the grant agreement."
    },
  ];

  return (
    <>
      <Header>
        <HeaderTitle>Frequently Asked Questions</HeaderTitle>
        <HeaderSubtitle>Get answers to common questions about the GPTF</HeaderSubtitle>
      </Header>
      <Wrapper>
        <Container>
          {faqs.map((faq, index) => (
            <FAQSection key={index}>
              <Question>
                <FaQuestionCircle /> {faq.question}
              </Question>
              <Answer>{faq.answer}</Answer>
            </FAQSection>
          ))}
        </Container>
      </Wrapper>
    </>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Header = styled.header`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  color: white;
  padding: 4rem 0;
  text-align: center;
  margin-top: 6rem;
  position: relative;
`;

const HeaderTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeaderSubtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 4rem 0;
  background: ${colors.light};
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const FAQSection = styled.div`
  margin-bottom: 2rem;
`;

const Question = styled.h3`
  font-size: 1.5rem;
  color: ${colors.tertiary};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  
  svg {
    color: ${colors.secondary};
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`;

const Answer = styled.p`
  animation: ${fadeIn} 1s ease-out;
  line-height: 1.6;
  color: ${colors.neutral};
`;

export default FAQPage;

import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";

// import ProjectBox from "../Elements/ProjectBox";

// import ProjectImg1
// Assets
import Transparency from "../../assets/img/transparency-value.png";
import Passion from "../../assets/img/paasion-value.jpg";
import Intergrity from "../../assets/img/intergrity-value.jpg";
import Accountability from "../../assets/img/accountability-value.webp";
import { useNavigate } from "react-router-dom";

export default function Services() {
  const navigate = useNavigate();
  return (
    <>

    <Wrapper id="services" >
      {/* <div className="lightBg" style={{ padding: "50px 0" }}>
          <div className="container">
            <ClientSlider style={{position: "relative", zIndex: 999, opacity: 0.3}}/>
          </div>       
        </div>       */}

      <div className="whiteBg" style={{ padding: "60px 0" }}>
      <div className="container">
        <HeaderInfo>
          <h1 className="font40 extraBold">What We Do</h1>
          <p className="font13">
            At the Game Products Trust Fund, we are dedicated to fulfilling our moral and ethical obligation to our stakeholders. Our mission encompasses:
          </p>
        </HeaderInfo>
        <ServiceBoxRow className="flex" style={{justifyContent: 'space-between'}}>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="roller"
              title="Grant Making"
              subtitle="We provide grants to emerging conservancies and wildlife councils to implement and maintain projects approved by our Board, focusing on wildlife conservation, management, and rural development."
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="monitor"
              title="Fund Allocation"
              subtitle="We allocate funds to conservancies, wildlife councils, protected areas, and approved individuals/organizations to support projects and programs related to wildlife conservation, management, and rural development."
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="browser"
              title="Community Engagement"
              subtitle="We support initiatives aimed at improving the relationship between people and wildlife, fostering a harmonious coexistence and mutual respect."
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="printer"
              title="Wildlife Resource Management"
              subtitle="We support efforts to enhance monitoring, management, protection, sustainable use, and development of wildlife resources in rural areas, ensuring their long-term viability and conservation."
            />
          </ServiceBoxWrapper>
        </ServiceBoxRow>
      </div>

        {/* <div className="lightBg">
          <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <h4 className="font15 semiBold">Our Core Values</h4>
              <h3 className="font30 extraBold">Integrity, Passion, Accountability, Commitment, Transparency</h3>
              <p className="font12">
                At the Game Products Trust Fund, we embody the iPACT:
                <br />
                <br />
                <b>Integrity:</b> What we profess, is what we do. Therefore, we shall always deliver on our service commitments.
                <br />
                <br />
                <b>Passion:</b> What we do is a way of life! Therefore, we are unwavering in our quest to pro-actively serve our stakeholders.
                <br />
                <br />
                <b>Accountability:</b> We are answerable for all our decisions and actions to our stakeholders.
                <br />
                <br />
                <b>Commitment:</b> We are dedicated to reinvest funds into sustainable conservation-and rural development initiatives.
                <br />
                <br />
                <b>Transparency:</b> We have the moral and ethical obligation to share all relevant information and decisions with our stakeholders.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Support Us" action={() => navigate("/support-us")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow>
            </AddLeft>
            <AddRight>
            <AddRightInner>
              <div className="flexNullCenter">
                <AddImgWrapp1 className="flexCenter">
                  <img src={Transparency} alt="transparency" style={{ width: '160%', height: '160%', objectFit: "cover" }} />
                </AddImgWrapp1>
                <AddImgWrapp2>
                  <img src={Passion} alt="passion" style={{ width: '160%', height: '160%' }} />
                </AddImgWrapp2>
              </div>
              <div className="flexNullCenter">
                <AddImgWrapp4>
                  <img src={Accountability} alt="accountability" style={{ width: '160%', height: '160%' }} />
                </AddImgWrapp4>
              </div>
            </AddRightInner>

            </AddRight>
          </Advertising>

          </div>
        </div> */}
      </div>
    </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  // width: 20%;
  margin-right: 24px;
  padding: 60px 0;
  
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 24px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;


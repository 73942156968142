import React from 'react';
import styled from 'styled-components';
import Organization from 'components/Organogram/Organization';

const Staff = () => {
  return (
    <>
      <PageWrapper>
        <Organization />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 5rem;
`;

export default Staff;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import TopNavbar from "../../components/Nav/TopNavbar";
import Footer from "../../components/Sections/Footer";
import { getTenders } from 'store/actions/cmsActions';
import DownloadFormModal from "components/BidDownloadInfoForm/DownloadFormModal";

const ProcurementBids = () => {
  const dispatch = useDispatch();
  const { tenders, categories } = useSelector((state) => state.cmsReducer);
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';

  const [sortBy, setSortBy] = useState("latest");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTender, setSelectedTender] = useState(null);

  useEffect(() => {
    dispatch(getTenders(organizationId));
    console.log("bids: ", tenders); 

  }, [dispatch]);

  const getTimeRemaining = (closingDate) => {
    const now = new Date().getTime();
    const distance = closingDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    return `${days}d ${hours}h`;
  };

  const sortedAndFilteredTenders = tenders
    ?.filter(tender => categoryFilter === "all" || tender.categories.includes(categoryFilter))
    .sort((a, b) => {
      if (sortBy === "latest") {
        return b.created - a.created;
      } else if (sortBy === "oldest") {
        return a.created - b.created;
      }
    });

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
  };

  const handleDownloadClick = (tenderId) => {
    setSelectedTender(tenderId);
    setIsModalOpen(true);
  };

  const handleFormSubmit = async (formData) => {
    setIsModalOpen(false);
    const tender = tenders.find(t => t.id === selectedTender);
    if (tender && tender.documentsLinks && tender.documentsLinks.length > 0) {
      const documentId = tender.documentsLinks[0];
      try {
        const response = documentId
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${tender.name}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading document:", error);
      }
    } else {
      console.error("No document link available for this tender");
    }
  };

  return (
    <>
      <TopNavbar />
      <Header>
        <HeaderTitle>Procurement Bids</HeaderTitle>
        <HeaderSubtitle>View and download the latest procurement bids</HeaderSubtitle>
      </Header>
      <Wrapper>
        <Container>
          <FilterContainer>
            <FilterOption>
              <FilterLabel htmlFor="sortBy">Sort By:</FilterLabel>
              <SortSelect id="sortBy" value={sortBy} onChange={handleSortChange}>
                <option value="latest">Latest</option>
                <option value="oldest">Oldest</option>
              </SortSelect>
            </FilterOption>
            <FilterOption>
              <FilterLabel htmlFor="categoryFilter">Category:</FilterLabel>
              <CategorySelect
                id="categoryFilter"
                value={categoryFilter}
                onChange={handleCategoryChange}
              >
                <option value="all">All Categories</option>
                {categories?.map(category => (
                  <option key={category.id} value={category.id}>{category.name}</option>
                ))}
              </CategorySelect>
            </FilterOption>
          </FilterContainer>
          <TenderList>
            {sortedAndFilteredTenders?.map((tender) => {
              const category = categories?.find((el) => el.id === tender.categories[0]);
              return (
                <TenderItem key={tender.id}>
                  <TenderTitle>{tender.name}</TenderTitle>
                  <TenderInfo>
                    <CategoryTag>{category?.name || 'General'}</CategoryTag>
                    <TenderDetails>
                      <span>Closing: {new Date(tender.date[1] * 1000).toLocaleDateString()}</span>
                      <span>Remaining: {getTimeRemaining(tender.date[1] * 1000)}</span>
                    </TenderDetails>
                  </TenderInfo>
                  <TenderDescription>{tender.description}</TenderDescription>
                  <ButtonContainer>
                    <DownloadButton onClick={() => handleDownloadClick(tender.id)}>
                      Download Bid Document
                    </DownloadButton>
                    <StatusButton status={tender.status}>
                      {tender.status === "0" ? 'Open' : 'Closed'}
                    </StatusButton>
                  </ButtonContainer>
                </TenderItem>
              );
            })}
          </TenderList>
        </Container>
      </Wrapper>
      {isModalOpen && (
        <DownloadFormModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleFormSubmit}
        />
      )}
    </>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const Header = styled.header`
  position: relative;
  background-color: ${colors.light};
  color: ${colors.tertiary};
  padding-top: 5rem;
  padding-bottom: 2rem;
  text-align: center;
  top: 70px;
  border-bottom: 2px solid ${colors.primary};

  @media (max-width: 768px) {
    padding: 1.5rem 0.5rem; // Reduce padding on mobile
  }
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.tertiary};

  @media (max-width: 768px) {
    font-size: 1.75rem; // Adjust font size for mobile
  }
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};

  @media (max-width: 768px) {
    font-size: 0.875rem; // Adjust font size for mobile
  }
`;

const Wrapper = styled.section`
  width: 100%;
  padding-top: 5rem;
  background: ${colors.light};
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column; // Stack on mobile
  margin-bottom: 2rem;
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    flex-direction: row; // Change to row on larger screens
    justify-content: space-between;
  }
`;

const FilterOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem; // Add margin for spacing in mobile

  @media (min-width: 768px) {
    margin-bottom: 0; // Remove bottom margin on larger screens
  }
`;

const FilterLabel = styled.label`
  margin-right: 0.5rem;
  color: ${colors.tertiary};
`;

const SortSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid ${colors.primary};
  background-color: white;
  color: ${colors.tertiary};
  border-radius: 4px;
`;

const CategorySelect = styled(SortSelect)``;

const TenderList = styled.div`
  display: grid;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Stack items vertically on mobile
  }
`;

const TenderItem = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid ${colors.primary};
`;

const TenderTitle = styled.h3`
  font-size: 1.25rem;
  color: ${colors.tertiary};
  margin-bottom: 0.5rem;
`;

const TenderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const CategoryTag = styled.span`
  background-color: ${colors.primary};
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
`;

const TenderDetails = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  color: ${colors.neutral};
`;

const TenderDescription = styled.p`
  margin-bottom: 1rem;
  color: ${colors.neutral};
`;


const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column; // Stack buttons on mobile
  gap: 0.5rem; // Space between buttons

  @media (min-width: 768px) {
    flex-direction: row; // Change to row on larger screens
    justify-content: space-between; // Evenly space buttons
  }
`;

const DownloadButton = styled.button`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.tertiary};
  }

  @media (max-width: 768px) {
    width: 100%; // Full width button on mobile
  }
`;

const StatusButton = styled.button`
  background-color: ${props => (props.status === "0" ? colors.secondary : colors.neutral)};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 100%; // Full width button on mobile
  }
`;

export default ProcurementBids;
import React from "react";
import styled, { keyframes } from "styled-components";
import { FaLeaf, FaHandshake, FaChartLine, FaBalanceScale, FaGlobe, FaEye } from 'react-icons/fa';
import HeaderBg from "../../assets/img/desert-safari.avif";

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const AboutMission = () => {
  return (
    <>
      <Header>
        <HeaderTitle>Who We Are</HeaderTitle>
        {/* <HeaderSubtitle>Game Products Trust Fund (GPTF)</HeaderSubtitle> */}
      </Header>
      <Wrapper>
        <Container>
          <Section>
            <SectionTitle>Our Story</SectionTitle>
            <FadeInParagraph>
              The Game Products Trust Fund (GPTF) was established through the Game Products Trust Fund Act (Act No. 7 of 1997) as a mechanism for ensuring that revenue obtained from the sale of wildlife products could be used exclusively towards wildlife conservation and community conservation and development programmes aimed at harmonizing the co-existence of people with such wildlife, and thus securing a future for wildlife outside of and within protected areas in Namibia.
            </FadeInParagraph>
          </Section>

          <Section>
            <SectionTitle>Our Mandate</SectionTitle>
            <FadeInParagraph>
              The GPTF is mandated by an act of parliament to collect revenue from wildlife and wildlife products recovered on state land and reinvest it into wildlife conservation, communal conservation and rural development programmes.
            </FadeInParagraph>
          </Section>

          <MissionVisionSection>
            <MissionVisionBox>
              <IconContainer><FaGlobe /></IconContainer>
              <BoxTitle>Our Mission</BoxTitle>
              <BoxContent>To re-invest funds from wildlife-use and other sources into sustainable conservation programmes and livelihoods</BoxContent>
            </MissionVisionBox>
            <MissionVisionBox>
              <IconContainer><FaEye /></IconContainer>
              <BoxTitle>Vision</BoxTitle>
              <BoxContent>To be a catalyst for sustainable wildlife management practices while improving livelihoods of those sharing their land with wildlife.</BoxContent>
            </MissionVisionBox>
          </MissionVisionSection>

          <Section>
            <SectionTitle>Our Core Values</SectionTitle>
            <ValuesList>
              <ValueItem>
                <FaBalanceScale /> Accountability: We strive to remain accountable to all our stakeholders
              </ValueItem>
              <ValueItem>
                <FaHandshake /> Integrity: What we profess is what we do
              </ValueItem>
              <ValueItem>
                <FaLeaf /> Commitment: We are committed to sustainable wildlife practices and improving livelihoods
              </ValueItem>
              <ValueItem>
                <FaChartLine /> Transparency: We are transparent in all our transactions with our stakeholders
              </ValueItem>
              <ValueItem>
                <FaHandshake /> Improvement: We strive to improve the livelihoods of people that share their land with wildlife
              </ValueItem>
            </ValuesList>
          </Section>

          <Section>
            <SectionTitle>Objectives</SectionTitle>
            <ObjectivesList>
              <ObjectiveItem>To make grants to emerging conservancies and wildlife councils for implementing and maintaining approved projects and programmes.</ObjectiveItem>
              <ObjectiveItem>To allocate funds to conservancies, wildlife councils, protected areas, and approved persons, organisations and institutions.</ObjectiveItem>
              <ObjectiveItem>To support measures aimed at improving the relationship between people and wildlife.</ObjectiveItem>
              <ObjectiveItem>To support improvements in the monitoring, management, protection, sustainable use and development of wildlife resources in rural areas.</ObjectiveItem>
            </ObjectivesList>
          </Section>
        </Container>
      </Wrapper>
    </>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Header = styled.header`
  background-image: url(${HeaderBg});  // Corrected the background property
  background-size: cover;
  background-position: center;
  color: white;
  padding: 4rem 0;
  text-align: center;
  margin-top: 6rem;
  position: relative;
`;

const HeaderTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeaderSubtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 4rem 0;
  background: ${colors.light};
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Section = styled.div`
  margin-bottom: 4rem;
`;

const SectionTitle = styled.h3`
  font-size: 2rem;
  color: ${colors.tertiary};
  margin-bottom: 1.5rem;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: ${colors.secondary};
  }
`;

const FadeInParagraph = styled.p`
  animation: ${fadeIn} 1s ease-out;
  line-height: 1.6;
  color: ${colors.neutral};
`;

const MissionVisionSection = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MissionVisionBox = styled.div`
  flex: 1;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${colors.primary};
  color: white;
  padding: 1rem;
  width: 60px;   // Ensuring the container is square
  height: 60px;  // Ensuring the container is square
  border-radius: 50%;  // Making it circular
  font-size: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const BoxTitle = styled.h4`
  font-size: 1.5rem;
  color: ${colors.tertiary};
  margin-bottom: 1rem;
`;

const BoxContent = styled.p`
  color: ${colors.neutral};
  line-height: 1.6;
`;

const ValuesList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ValueItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: ${colors.neutral};
  
  svg {
    color: ${colors.secondary};
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`;

const ObjectivesList = styled.ul`
  padding-left: 1.5rem;
`;

const ObjectiveItem = styled.li`
  color: ${colors.neutral};
  margin-bottom: 1rem;
  line-height: 1.6;
  
  &::marker {
    color: ${colors.secondary};
  }
`;

export default AboutMission;

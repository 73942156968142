import React from 'react';
import styled from 'styled-components';
import Partner1 from "../../assets/img/wildlife-cheeter.jpg"; 
import Partner2 from "../../assets/img/wildlife-cheeter.jpg";
import Partner3 from "../../assets/img/wildlife-cheeter.jpg";
import Sponsor1 from "../../assets/img/wildlife-cheeter.jpg";
import Sponsor2 from "../../assets/img/wildlife-cheeter.jpg";

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const PartnersSponsors = () => {
  return (
    <>
      <Header>
        <HeaderTitle>Our Partners and Sponsors</HeaderTitle>
      </Header>
      <Wrapper>
        <Container>
          <Section>
            <SectionTitle>Partners</SectionTitle>
            <PartnersGrid>
              <PartnerCard>
                <PartnerImage src={Partner1} alt="Partner 1" />
                <PartnerName>Partner 1</PartnerName>
              </PartnerCard>
              <PartnerCard>
                <PartnerImage src={Partner2} alt="Partner 2" />
                <PartnerName>Partner 2</PartnerName>
              </PartnerCard>
              <PartnerCard>
                <PartnerImage src={Partner3} alt="Partner 3" />
                <PartnerName>Partner 3</PartnerName>
              </PartnerCard>
            </PartnersGrid>
          </Section>

          <Section>
            <SectionTitle>Sponsors</SectionTitle>
            <SponsorsGrid>
              <SponsorCard>
                <SponsorImage src={Sponsor1} alt="Sponsor 1" />
                <SponsorName>Sponsor 1</SponsorName>
              </SponsorCard>
              <SponsorCard>
                <SponsorImage src={Sponsor2} alt="Sponsor 2" />
                <SponsorName>Sponsor 2</SponsorName>
              </SponsorCard>
            </SponsorsGrid>
          </Section>
        </Container>
      </Wrapper>
    </>
  );
};

const Header = styled.header`
  background-color: ${colors.primary};
  color: white;
  padding: 4rem 0;
  text-align: center;
  margin-top: 6rem;
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 4rem 0;
  background: ${colors.light};
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Section = styled.div`
  margin-bottom: 4rem;
`;

const SectionTitle = styled.h3`
  font-size: 2rem;
  color: ${colors.tertiary};
  margin-bottom: 1.5rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: ${colors.secondary};
  }
`;

const PartnersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const PartnerCard = styled.div`
  background: white;
  padding: 1.5rem;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const PartnerImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
`;

const PartnerName = styled.h4`
  font-size: 1.25rem;
  color: ${colors.tertiary};
`;

const SponsorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const SponsorCard = styled.div`
  background: white;
  padding: 1.5rem;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const SponsorImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
`;

const SponsorName = styled.h4`
  font-size: 1.25rem;
  color: ${colors.tertiary};
`;

export default PartnersSponsors;

import Woman1 from "../assets/img/team/woman-team-1.avif";
import Man1 from "../assets/img/team/man-team-1.avif";
import Man2 from "../assets/img/team/man-team-2.avif";
import Man3 from "../assets/img/team/man-team-3.avif";

export const technicalAdvisoryGroupData = {
    name: "Mrs. Elly Hamunyela",
    title: "Chairperson",
    image: Woman1,
    children: [
      {
        name: "Mr. Bennet Kahuure",
        title: "Member",
        image: Man1,
      },
      {
        name: "Mr. Boas Erckie",
        title: "Member",
        image: Man2,
      },
      {
        name: "Mr. Kenneth Uiseb",
        title: "Member",
        image: Man3,
      },   
    ],
  };

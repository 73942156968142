import { createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../utils/firebase";
import {  onSnapshot, collection, query, where  } from "firebase/firestore";
 
import { 
    FETCH_ARTICLES_SUCCESS, ON_NOTIFICATION, FETCH_CATEGORIES_SUCCESS, FETCH_PROJECTS_SUCCESS,
    FETCH_TENDERS_SUCCESS, FETCH_DOCUMENTS_SUCCESS
} from "../types/cmsTypes";
  

const dayjs = require('dayjs')
const now = dayjs().format()
const updated = dayjs(now).unix()
const created = dayjs(now).unix()

export function articlesSuccess(articles) {
  return {
    type:  FETCH_ARTICLES_SUCCESS,
    payload: {
      articles,
    },
  };
}

export function notifySuccess(notification){
  return {
    type:  ON_NOTIFICATION,
    payload: notification,
  };
}

export function categoriesSuccess(categories) {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: {
      categories,
    },
  };
}

export function projectsSuccess(projects) {
  return {
    type: FETCH_PROJECTS_SUCCESS,
    payload: {
      projects,
    },
  };
}

export function documentsSuccess(documents) {
  return {
    type: FETCH_DOCUMENTS_SUCCESS,
    payload: {
      documents,
    },
  };
}

export function tendersSuccess(tenders) {
  return {
    type:  FETCH_TENDERS_SUCCESS,
    payload: {
      tenders,
    },
  };
}

export function storageSuccess(storage) {
  return {
    type:  FETCH_DOCUMENTS_SUCCESS,
    payload: {
      storage,
    },
  };
}







// Get Articles
export const getArticles = createAsyncThunk(
  "cms/getArticles",
  async (organizationId, { dispatch, rejectWithValue }) => {
    try {
 
    const q = query(
      collection(db, "/cms/content/articles"),
      where("organizationId", "==", organizationId),
     ); 

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const articles  = [];
      querySnapshot.forEach((doc) => {
        articles.push({ id: doc.id, ...doc.data() });
      }); 

      // Sort articles by 'created' date in descending order
      const sortedArticles = articles.slice().sort((a, b) => {
        // Assuming 'created' is a Unix timestamp (or can be converted to a number)
        return b.created - a.created; // Sort in descending order
      });
      
 // Dispatch the sorted articles using Redux action
    dispatch(articlesSuccess(sortedArticles));

    });

     return unsubscribe;

    } catch (error) {
      return rejectWithValue(error.message || "Failed to get articles");
    }
  }
);

// Get Documents
export const getDocuments = createAsyncThunk(
  "cms/getDocuments",
  async (organizationId, { dispatch, rejectWithValue }) => {
    try {
 
    const q = query(
      collection(db, "/cms/content/documents"),
      where("organizationId", "==", organizationId),
     ); 

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const documents  = [];
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      }); 

      // Sort Documents by 'created' date in descending order
      const sortedDocuments = documents.slice().sort((a, b) => {
        // Assuming 'created' is a Unix timestamp (or can be converted to a number)
        return b.created - a.created; // Sort in descending order
      });
      
 // Dispatch the sorted Documents using Redux action
    dispatch(documentsSuccess(sortedDocuments));

    });

     return unsubscribe;

    } catch (error) {
      return rejectWithValue(error.message || "Failed to get Documents");
    }
  }
);

 

 


// Get Categories
export const getCategories = createAsyncThunk(
  "cms/getCategories",
  async (organizationId, { dispatch, rejectWithValue }) => {
    try {
 
    const q = query(
      collection(db, "/cms/content/categories"),
      where("organizationId", "==", organizationId),
     ); 

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const categories = [];
      querySnapshot.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      }); 
       // Sort articles by 'created' date in descending order
      const sortedCategories = categories.slice().sort((a , b ) => {
        // Assuming 'created' is a Unix timestamp (or can be converted to a number)
        return b.created - a.created; // Sort in descending order
      });
      
 // Dispatch the sorted articles using Redux action
    dispatch(categoriesSuccess(sortedCategories));

    });

     return unsubscribe;

    } catch (error) {
      return rejectWithValue(error.message || "Failed to get articles");
    }
  }
);

 

// Get projects
export const getProjects = createAsyncThunk(
  "cms/getProjects",
  async (organizationId, { dispatch, rejectWithValue }) => {
    try {
 
    const q = query(
      collection(db, "/cms/content/projects"),
      where("organizationId", "==", organizationId),
     ); 

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const projects  = [];
      querySnapshot.forEach((doc) => {
        projects.push({ id: doc.id, ...doc.data() });
      }); 
       // Sort articles by 'created' date in descending order
      const sortedProjects = projects.slice().sort((a,  b ) => {
        // Assuming 'created' is a Unix timestamp (or can be converted to a number)
        return b.created - a.created; // Sort in descending order
      });
      
 // Dispatch the sorted articles using Redux action
    dispatch(projectsSuccess(sortedProjects));

    });

     return unsubscribe;

    } catch (error) {
      return rejectWithValue(error.message || "Failed to get articles");
    }
  }
);
 



// Get projects
export const getTenders = createAsyncThunk(
  "cms/getProjects",
  async (organizationId, { dispatch, rejectWithValue }) => {
    try {
 
    const q = query(
      collection(db, "/cms/content/tenders"),
      where("organizationId", "==", organizationId),
     ); 

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tenders  = [];
      querySnapshot.forEach((doc) => {
        tenders.push({ id: doc.id, ...doc.data() });
      }); 
       // Sort articles by 'created' date in descending order
      const sortedTenders = tenders.slice().sort((a , b ) => {
        // Assuming 'created' is a Unix timestamp (or can be converted to a number)
        return b.created - a.created; // Sort in descending order
      });
      
 // Dispatch the sorted articles using Redux action
    dispatch(tendersSuccess(sortedTenders));

    });

     return unsubscribe;

    } catch (error) {
      return rejectWithValue(error.message || "Failed to get articles");
    }
  }
);
 
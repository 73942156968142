import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TopNavbar from "../../components/Nav/TopNavbar";
import { FaImages } from 'react-icons/fa';

const PhotoGallery = () => {
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalImage, setModalImage] = useState(null);
  const [modalIndex, setModalIndex] = useState(null);
  const imagesPerPage = 9;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('hhhttps://picsum.photos/v2/list?page=1&limit=30');
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openModal = (index) => {
    setModalImage(currentImages[index]);
    setModalIndex(index);
  };

  const closeModal = () => {
    setModalImage(null);
    setModalIndex(null);
  };

  const nextImage = () => {
    if (modalIndex !== null && modalIndex < currentImages.length - 1) {
      setModalIndex(modalIndex + 1);
      setModalImage(currentImages[modalIndex + 1]);
    }
  };

  const prevImage = () => {
    if (modalIndex !== null && modalIndex > 0) {
      setModalIndex(modalIndex - 1);
      setModalImage(currentImages[modalIndex - 1]);
    }
  };

  const totalPages = Math.ceil(images.length / imagesPerPage);

  return (
    <>
      <TopNavbar />
      <Wrapper>
        <Header>
          <HeaderTitle>Photo Gallery</HeaderTitle>
          <HeaderSubtitle>
            Explore our collection of wildlife and nature photographs.
          </HeaderSubtitle>
        </Header>
        {currentImages.length > 0 ? (
          <>
            <GalleryGrid>
              {currentImages.map((image, index) => (
                <ImageCard key={image.id} onClick={() => openModal(index)}>
                  <Image src={image.download_url} alt={image.author} />
                  <ImageInfo>
                    <ImageDescription>Photo by {image.author}</ImageDescription>
                  </ImageInfo>
                </ImageCard>
              ))}
            </GalleryGrid>
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => (
                <PageButton
                  key={i}
                  onClick={() => paginate(i + 1)}
                  active={currentPage === i + 1}
                >
                  {i + 1}
                </PageButton>
              ))}
            </Pagination>
          </>
        ) : (
          <NoImagesMessage>
            <FaImages size={60} />
            <NoImagesTitle>No Images Available</NoImagesTitle>
            <NoImagesText>
              There are currently no images to display. Please check back later for updates to our gallery.
            </NoImagesText>
          </NoImagesMessage>
        )}
        {modalImage && (
          <ModalOverlay onClick={closeModal}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              <ModalImage src={modalImage.download_url} alt={modalImage.author} />
              <ModalDescription>Photo by {modalImage.author}</ModalDescription>
              <ModalControls>
                <ControlButton onClick={prevImage} disabled={modalIndex === 0}>
                  &lt;
                </ControlButton>
                <ControlButton
                  onClick={nextImage}
                  disabled={modalIndex === currentImages.length - 1}
                >
                  &gt;
                </ControlButton>
              </ModalControls>
            </ModalContent>
          </ModalOverlay>
        )}
      </Wrapper>
    </>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const Wrapper = styled.div`
  background-color: ${colors.light};
  min-height: 100vh;
  padding: 80px 20px 40px 20px;
  margin-top: 70px;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 40px;
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  color: ${colors.tertiary};
  margin-bottom: 10px;
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
`;

const ImageCard = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background: white;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ImageInfo = styled.div`
  padding: 10px;
  background-color: ${colors.light};
`;

const ImageDescription = styled.p`
  font-size: 0.9rem;
  color: ${colors.neutral};
  margin: 0;
  text-align: center;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const PageButton = styled.button`
  background-color: ${(props) => (props.active ? colors.primary : 'white')};
  color: ${(props) => (props.active ? 'white' : colors.primary)};
  border: 1px solid ${colors.primary};
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
`;

const NoImagesMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  text-align: center;
  color: ${colors.neutral};
`;

const NoImagesTitle = styled.h2`
  font-size: 2rem;
  color: ${colors.tertiary};
  margin: 20px 0 10px 0;
`;

const NoImagesText = styled.p`
  font-size: 1rem;
  max-width: 500px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
  background-color: ${colors.light};
`;

const ModalDescription = styled.div`
  padding: 15px;
  font-size: 1rem;
  color: ${colors.neutral};
  text-align: center;
`;

const ModalControls = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  padding: 0 20px;
`;

const ControlButton = styled.button`
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: rgba(0, 0, 0, 0.8);
  }
`;

export default PhotoGallery;

import React from 'react';
import styled from 'styled-components';
import LogoImg from "../../assets/img/logo.png";

const GeneralConditionsPage = () => {

  const GoodsRequestForQuotation = "/data/ConditionsOfContract/General-Conditions-of-Contract-for-Request-for-QuotationGoods-4.pdf"
  const NonConsultancyServicesTimeBased = "/data/ConditionsOfContract/GCC-Non-Consultancy-Services-Time-Based-1.pdf"
  const WorksRequestForSealedQuotation = "/data/ConditionsOfContract/General-Conditions-for-Works-Request-for-Sealed-Quotation-2017-2.pdf"
  const NonConsultancyServicesLumpSum = "/data/ConditionsOfContract/GCC-Non-Consultancy-Services-Lump-Sum-2017-6.pdf"

  const documents = [
    {
      title: "General Conditions of Contract for Goods Request for Quotation",
      size: "1.1 MB",
      link: GoodsRequestForQuotation
    },
    {
      title: "General Conditions of Contract for Non-Consultancy Services (Time Based)",
      size: "1.0 MB",
      link: NonConsultancyServicesTimeBased
    },
    {
      title: "General Conditions of Contract for Works Request for Sealed Quotation",
      size: "1.4 MB",
      link: WorksRequestForSealedQuotation
    },
    {
      title: "General Conditions of Contract Non Consultancy Services (Lump-Sum)",
      size: "1.2 MB",
      link: NonConsultancyServicesLumpSum
    },    
  ];

  return (
    <PageWrapper>
      <TitleWrapper>
        <Title>GENERAL CONDITIONS OF CONTRACT</Title>
        <TitleLine />
      </TitleWrapper>
      <Container>
        {documents.map((doc, index) => (
          <DocumentCard key={index}>
            <Logo src={LogoImg} alt="Logo" />
            <Content>
              <DocumentTitle>{doc.title}</DocumentTitle>
              <DocumentSize>{doc.size}</DocumentSize>
              <Line />
              <DownloadButton href={doc.link} download>Download</DownloadButton>
            </Content>
          </DocumentCard>
        ))}
      </Container>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  position: relative;
  padding: 20px; /* Adjusted for mobile */
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 5rem;
  top: 70px;

  @media (max-width: 768px) {
    padding: 10px; /* Less padding on smaller screens */
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: 20px; /* Adjusted for mobile */
  padding-top: 20px;
  color: #9bbb59;
`;

const Title = styled.h1`
  font-size: 2rem; /* Adjusted for mobile */
  font-weight: 600;
  margin: 0;
  padding-top: 5rem;
  padding-bottom: 2rem;  

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller title on mobile */
  }
`;

const TitleLine = styled.div`
  position: absolute;
  margin-top: 3rem;
  top: 20%;
  left: 0;
  width: 15%;
  height: 4px;
  background-color: #9bbb59;
  transform: translateY(-50%);
`;

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr); /* 2 columns for larger screens */
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 1 column on smaller screens */
  }
`;

const DocumentCard = styled.div`
  color: #c4ba91;
  display: flex;
  align-items: center;
  background: #c3b98f21;
  padding: 15px; /* Reduced padding for mobile */
  border-radius: 8px;
  border: 0.5px solid #61743c;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack elements on mobile */
    align-items: flex-start; /* Align to start */
    padding: 10px; /* Further reduced padding on mobile */
  }
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border: 0.5px solid #61743c;
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 40px; /* Smaller logo on mobile */
    height: 40px;
    margin-right: 10px; /* Adjusted margin */
  }
`;

const Content = styled.div`
  flex: 1;
`;

const DocumentTitle = styled.h2`
  font-size: 1.25rem;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller title on mobile */
  }
`;

const DocumentSize = styled.p`
  font-size: 0.875rem;
  color: #666;
  margin: 5px 0;

  @media (max-width: 768px) {
    font-size: 0.75rem; /* Smaller size text on mobile */
  }
`;

const Line = styled.div`
  height: 1px;
  background-color: #eee;
  margin: 10px 0;
`;

const DownloadButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  font-size: 0.875rem;
  color: #fff;
  background-color: #9bbb59;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #005bb5;
  }

  @media (max-width: 768px) {
    padding: 8px 16px; /* Smaller button padding on mobile */
    font-size: 0.75rem; /* Smaller font size on mobile */
  }
`;

export default GeneralConditionsPage;

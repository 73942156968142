import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const InputForm = ({ onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    input: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInputSubmit = (e) => {
    e.preventDefault();

    const apiEndpoint = 'https://qualis-email-server.vercel.app/api/api/custom-mails/gptf/gptf-input';
    // const apiEndpoint = 'http://localhost:5001/api/custom-mails/gptf/gptf-input';

    // Fetch options
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData) // Convert form data to JSON
    };

    // Make the request
    fetch(apiEndpoint, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        alert('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          input: '',
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error occurred while sending the message. Please try again later.');
      });
  };

  return (
    <FormOverlay>
      <FormContainer>
        <h2>Give Us Your Input</h2>
        <form onSubmit={handleInputSubmit}>
          <Input name="name" type="text" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
          <Input name="email" type="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
          <TextArea name="input" placeholder="Your Input" value={formData.input} onChange={handleChange} required />
          <Button type="submit">Submit</Button>
        </form>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </FormContainer>
    </FormOverlay>
  );
};



const FormOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FormContainer = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border: 2px solid #9bbb59;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  font-family: 'Courier New', Courier, monospace;
  transform: perspective(800px) rotateX(5deg) rotateY(-5deg);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;

  &:hover {
    transform: perspective(800px) rotateX(0deg) rotateY(0deg);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }

  h2 {
    color: #9bbb59;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #9bbb59;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #9bbb59;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  background-color: #9bbb59;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #006666;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #9bbb59;
`;

export default InputForm;

import React from "react";
import { useSelector, useDispatch } from "react-redux";


// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import AboutUs from "../components/Sections/AboutUs";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import ErrorComponent from "../components/ErrorComponent/ErrorComponent";
import PartnersCarousel from "../components/Sections/PartnersCarousel";
import Blog from "../components/Sections/Blog";
import Contact from "../components/Sections/Contact";
import { getCategories } from "store/actions/cmsActions";

export default function Landing() {

  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';
 

  const dispatch = useDispatch();
 
  

  React.useEffect(() => {
    dispatch(getCategories(organizationId));
  }, [dispatch, organizationId]);

  return (
    <>
      <TopNavbar />
      <Header />
      <section id="about">
        <AboutUs />
      </section>
      <Services />
      <Projects />
      {/* <PartnersCarousel /> */}
      <Blog />
      <Contact />
    </>
  );
}



import Woman1 from "../assets/img/team/woman-team-1.avif";
import Woman2 from "../assets/img/team/woman-team-2.avif";
import Woman3 from "../assets/img/team/woman-team-3.avif";

export const financeRiskAuditCommitteeData = {
    name: "Mrs. Albertina Nankela",
    title: "Chairperson",
    image: Woman1,
    children: [
      {
        name: "Ms. Juraney Gaingos",
        title: "Member",
        image: Woman2,
      },
      {
        name: "Mr. Pendukeni Fillippus",
        title: "Member",
        image: Woman3,
      },   
    ],
  };

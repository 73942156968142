import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import TopNavbar from "../../components/Nav/TopNavbar";

const SelectedArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { articles } = useSelector((state) => state.cmsReducer);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const foundArticle = articles.find((a) => a.id === id);
    setArticle(foundArticle);
  }, [id, articles]);

  if (!article) {
    return <LoadingWrapper>Loading...</LoadingWrapper>;
  }

  return (
    <>
      <TopNavbar />
      <Wrapper>
        <Container>
          <BackButton onClick={() => navigate('/blogs')}>
            ← Back to Articles
          </BackButton>
          <Header>
            <Title>{article?.title}</Title>
            <Meta>
              <Author>By GPTF Team</Author>
              <DateWrapper>{new Date(article?.created * 1000).toLocaleDateString()}</DateWrapper>
            </Meta>
          </Header>
          {article.imageUrl && (
            <ImageWrapper>
              <Image src={article?.imageUrl} alt={article?.title} />
            </ImageWrapper>
          )}
          <Content dangerouslySetInnerHTML={{ __html: article.content }} />
          <TagsWrapper>
            {article.tags && article.tags.map((tag, index) => (
              <Tag key={index}>{tag}</Tag>
            ))}
          </TagsWrapper>
          <BackButton onClick={() => navigate('/blogs')}>
            ← Back to Articles
          </BackButton>
        </Container>
      </Wrapper>
    </>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const Wrapper = styled.div`
  background-color: ${colors.light};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 40px;
  margin-top: 70px;
`;

const Container = styled.div`
  max-width: 800px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  overflow: hidden;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: ${colors.primary};
  font-size: 16px;
  cursor: pointer;
  padding: 10px 0;
  margin-bottom: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: ${colors.tertiary};
  }
`;

const Header = styled.header`
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  color: ${colors.tertiary};
  margin-bottom: 10px;
  line-height: 1.2;
`;

const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${colors.neutral};
  font-size: 0.875rem;
`;

const Author = styled.span`
  font-style: italic;
`;

const DateWrapper = styled.span`
  font-style: italic;
`;

const ImageWrapper = styled.div`
  margin: -40px -40px 30px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Content = styled.div`
  font-size: 1rem;
  line-height: 1.6;
  color: ${colors.neutral};

  p {
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 30px 0 15px;
    color: ${colors.tertiary};
  }

  a {
    color: ${colors.primary};
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${colors.tertiary};
    }
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

const Tag = styled.span`
  background-color: ${colors.light};
  color: ${colors.tertiary};
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: ${colors.tertiary};
`;

export default SelectedArticle;
import React, { useState } from 'react';
import styled from 'styled-components';

const HowToGetFunded = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Container>
      <HeroSection>
        <HeroTitle>How to Get Funded</HeroTitle>
        <HeroSubtitle>We can turn your ideas into impactful projects with GPTF funding.</HeroSubtitle>
      </HeroSection>

      <ContentSection>
        <SectionTitle>Introduction</SectionTitle>
        <Text>
          The GPTF has funded projects ranging from the provision of water for wildlife to constructing structures, such
          as bridges to facilitate the movement of people in communities that are normally cut off during floods. There
          are several things you must do to turn your idea into a proposal and each step of this process provides the
          tools and information you need to do this – please read them carefully as you progress.
        </Text>
        <Text>
          Initial enquiries can be submitted at any time, however, there are four deadlines for full applications every
          year which can be found in the 'Closing Dates' section. The application process is competitive since the GPTF
          is not in a position to fund each and every application received; each application is judged on its merits so
          it is in your best interest to show how your project meets the GPTF criteria.
        </Text>
        <Text>
          Please feel free to contact the Fund Management Office (FMO) in advance if you want to discuss anything about
          your idea.
        </Text>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Application Process</SectionTitle>
        <ProcessList>
          <ProcessItem onClick={() => handleOpenModal('The GPTF support projects that meet one or all of its objectives. Generally, the GPTF considers projects that are aimed at improving the relationship between people and wildlife. Allocation of funds is based on priorities determined by the Board on an annual basis, The GPTF objectives and priorities for funding are outlined under the forms and guidelines section.')}>
            <ProcessStep>1</ProcessStep>
            <ProcessText>Identify and confirm if your project meets the GPTF criteria</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('The dates given in the closing dates’ section should be observed at all times when submitting grant applications to the GPTF. If and when an application is successful in receiving a grant, the recipient will be required to sign a Finance Agreement within three months and start work no later than three months after that.')}>
            <ProcessStep>2</ProcessStep>
            <ProcessText>Check application submission closing dates</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('The application forms contain detailed questions relating to the organisation, the goal and objective of the project to be undertaken and the amount of funds required from the GPTF. Once the application is received by the FMO, it is checked for compliance and see if it contains the details and information required by the GPTF. The FMO generally contacts the applicant within three weeks of the closing date to raise any questions or request additional information.')}>
            <ProcessStep>3</ProcessStep>
            <ProcessText>Submit completed application</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('The grant application is technically assessed and appraised by the Technical Advisory Group who makes recommendations to the GPTF Board of Trustees. The recommended project proposals are reviewed and a funding decision is made by the GPTF Board of Trustees. Applicants should note that the GPTF receives a lot of applications than the funding available, which unfortunately means that the GPTF is not in a position to support all the applications it receives at each round of applications. The FMO will send the applicant a response letter within two (2) weeks of a funding decision being made.')}>
            <ProcessStep>4</ProcessStep>
            <ProcessText>Application checked for compliance</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('The FMO will send the applicant a response letter within two (2) weeks of a funding decision being made. If successful, the response letter will include the details of the grant and the supporting documents that need to be submitted to enable the Grant Agreement to be issued for signing by both parties. The grant approval letter will detail all the documents required, and the supporting documents required at this time will include the evidence of funding that makes up the total cost of the project, quotations or tender report summary and permissions.')}>
            <ProcessStep>5</ProcessStep>
            <ProcessText>Application appraised by the Technical Advisory Group</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('Copies of the Grant Agreement outlining the terms and conditions of the grant will be sent to the applicant. It is important to note that no physical work can start on the project until the Grant Agreement has been signed by all parties.')}>
            <ProcessStep>6</ProcessStep>
            <ProcessText>Board Review and Decision on Funding</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('The project can commence according to plan as soon as the applicant receives the Grant Agreement. The FMO will work with the applicant to ensure that the project is going to plan. The Grantee will be required to submit the first progress report by the date set out in the Grant Agreement, this allows the FMO to establish the start and completion dates which by this time have generally changed from those stated in the application.')}>
            <ProcessStep>7</ProcessStep>
            <ProcessText>Funding notification</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('The Grantee will be required to provide regular progress reports by no later than the dates to be agreed to in the Grant Agreement. Claims for payment will only be processed when accompanied by a detailed progress report.')}>
            <ProcessStep>8</ProcessStep>
            <ProcessText>Pre-finance Agreement checks</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('In the lead up to the project completion, you will be sent a plaque to install in recognition of the GPTF grant. Once the project is complete, the grantee need to submit a final report and claim any outstanding payments within four weeks of project completion.')}>
            <ProcessStep>9</ProcessStep>
            <ProcessText>Finance Agreement issued</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('The FMO will work with the applicant to ensure that the project is going to plan.')}>
            <ProcessStep>10</ProcessStep>
            <ProcessText>Project work starts</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('After the first report, the Grantee will be required to provide regular progress reports by no later than the dates to be agreed to in the Grant Agreement. Claims for payment will only be processed when accompanied by a detailed progress report.')}>
            <ProcessStep>11</ProcessStep>
            <ProcessText>Project completion</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('Once the project is complete, the grantee needs to submit a final report and claim any outstanding payments within four weeks of project completion.')}>
            <ProcessStep>12</ProcessStep>
            <ProcessText>Submit project progress reports</ProcessText>
          </ProcessItem>
          <ProcessItem onClick={() => handleOpenModal('The current funding round has been closed. Please keep up with our information plartforms for the next round of funding.')}>
            <ProcessStep>13</ProcessStep>
            <ProcessText>The closing dates section</ProcessText>
          </ProcessItem>          
        </ProcessList>
      </ContentSection>

      {modalOpen && (
        <Modal onClick={handleCloseModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalClose onClick={handleCloseModal}>X</ModalClose>
            <ModalText>{modalContent}</ModalText>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  position: relative;
`;

const HeroSection = styled.section`
  background-color: #60743c;
  color: white;
  padding-top: 7rem;
  padding-bottom: 2rem;
  text-align: center;
  margin-top: 5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const ContentSection = styled.section`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #60743c;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

const ProcessList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ProcessItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid #60743c;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #60743c;
    color: white;
    transform: translateY(-5px);
  }
`;

const ProcessStep = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
`;

const ProcessText = styled.div`
  font-size: 1rem;
  flex-grow: 1;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  // border: solid 0.8px solid #60743c;
  // border: 8px solid #red;
  border-radius: 8px;
  position: relative;
  max-width: 600px;
  width: 80%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const ModalClose = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #60743c;

  &:hover {
    color: red;
  }
`;

const ModalText = styled.p`
  font-size: 1rem;
  line-height: 1.8;
`;

export default HowToGetFunded;

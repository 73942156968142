// import Man1 from "../assets/img/team/man-team-1.avif";
// import Man2 from "../assets/img/team/man-team-2.avif";
// import Man3 from "../assets/img/team/man-team-3.avif";
// import Woman1 from "../assets/img/team/woman-team-1.avif";
// import Woman2 from "../assets/img/team/woman-team-2.avif";
// import Woman3 from "../assets/img/team/woman-team-3.avif";

import Man1 from "../assets/img/team/team-member-paceholder.avif"
import Man2 from "../assets/img/team/team-member-paceholder.avif"
import Man3 from "../assets/img/team/team-member-paceholder.avif"
import Woman1 from "../assets/img/team/team-member-paceholder.avif"
import Woman2 from "../assets/img/team/team-member-paceholder.avif"
import Woman3 from "../assets/img/team/team-member-paceholder.avif"
import img from "../assets/img/team/team-member-paceholder.avif"

export const teamMembers = [
  {
    section: "ADMINISTRATION AND OPERATIONS",
    members: [
      {
        name: "Bestinah Lishoni",
        role: "Accountant",
        image: Woman1,
        description: "Handles the organization's financial records and ensures compliance with financial regulations.",
        socials: {
          facebook: "#",
          twitter: "#",
          googlePlus: "#",
          email: "#"
        }
      },
      {
        name: "Helen Haipinge",
        role: "Human Resources Officer",
        image: Woman2,
        description: "Manages HR policies, recruitment, and employee relations within the organization.",
        socials: {
          facebook: "#",
          twitter: "#",
          googlePlus: "#",
          email: "#"
        }
      },
      {
        name: "Merlin Mercia Katjiru",
        role: "Procurement Officer",
        image: Woman3,
        description: "Oversees the purchasing of goods and services, ensuring cost-efficiency and quality.",
        socials: {
          facebook: "#",
          twitter: "#",
          googlePlus: "#",
          email: "#"
        }
      },
      {
        name: "Paulus Kudumo",
        role: "Hygiene Officer",
        image: Man1,
        description: "Ensures the organization's facilities meet health and safety standards.",
        socials: {
          facebook: "#",
          twitter: "#",
          googlePlus: "#",
          email: "#"
        }
      }
    ]
  },
  {
    section: "INTERNS",
    members: [
      {
        name: "Ester Nambala",
        role: "Procurement Intern",
        image: Woman1,
        description: "Assists the procurement officer with tasks related to purchasing and supply management.",
        socials: {
          facebook: "#",
          twitter: "#",
          googlePlus: "#",
          email: "#"
        }
      },
      {
        name: "Sarti Ananias",
        role: "Finance Intern",
        image: Woman2,
        description: "Supports the accounting team with financial tasks and learning opportunities in finance.",
        socials: {
          facebook: "#",
          twitter: "#",
          googlePlus: "#",
          email: "#"
        }
      },
      {
        name: "Wilhellem Fudheni",
        role: "Human-Wildlife Conflict Management (HWCM) Intern",
        image: Man2,
        description: "Assists in projects aimed at resolving conflicts between local communities and wildlife.",
        socials: {
          facebook: "#",
          twitter: "#",
          googlePlus: "#",
          email: "#"
        }
      }
    ]
  }
];